import React, { useState, useEffect, useRef  } from "react";
import { Typography, Flex, Button, theme} from "antd";
import {useNavigate} from "react-router-dom";
import styled, { css } from "styled-components";
import { ASSETS } from "../constants";

const { Title, Text } = Typography;
const { useToken } = theme;

const SectionContainer = styled.div`
  padding: 40px 120px;
  margin-top: 40px;
  position: relative;
  margin-bottom: 40px;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const HeaderSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 48px;

  @media (max-width: 768px) {
    margin-bottom: 64px;
    align-items: center;
    text-align: center;
  }
`;

const ButtonLeft = styled(Button)`
    height: 49px;
    border-top-left-radius: 200px;
    border-bottom-left-radius: 200px;
    padding: 16px 40px;
    font-size: 14px;
    font-weight: 400;
    font-family: "Gilroy-Regular";
    border: 1px solid ${(props) => props.theme.token.colorPrimary};
`;

const ButtonRight = styled(Button)`
    height: 49px;
    border-top-right-radius: 200px;
    border-bottom-right-radius: 200px;
    padding: 16px 40px;
    font-size: 14px;
    font-weight: 400;
    font-family: "Gilroy-Regular";
    border: 1px solid ${(props) => props.theme.token.colorPrimary};
`;

const PlanContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 32px;
  margin-top: 24px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const PlanCardContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
  background: #fff;
  border-radius: 20px;
  padding: 20px;
  box-shadow: 0px 0px 100px 0px #0000000F;
  position: relative;
  // top: ${(props) => (props.featured ? "20px" : "0")};
  // box-shadow: ${(props) => (props.featured ? `0px 0px 30px 5px ${props.theme.token.colorPrimary}90` : "0px 0px 100px 0px #0000000F")};
  height: 100%;
  flex: 1;
  justify-content: space-between;

    @media (max-width: 768px) {
        top: 0;
    }
`;

const PlanTitle = styled(Text)`
  font-size: 18px;
  font-weight: 400;
  font-family: "Gilroy-Bold";
  line-height: 22.28px;
  color: ${(props) => props.theme.token.pricingTextColorGrey};
`;

const SubText = styled(Text)`
    font-size: 14px;
    font-weight: 400;
    font-family: "Gilroy-Regular";
    color: ${(props) => props.theme.token.pricingTextColorGrey};
`;

const PriceText = styled(Text)`
  font-size: 24px;
  font-weight: bold;
  margin: 16px 0;
`;

const FeatureList = styled.ul`
  text-align: left; /* Align the list to the left */
  align-self: flex-start;
  margin: 0;
  padding: 0;
  list-style: none; /* Remove default bullet points */
  color: ${(props) => props.theme.token.pricingTextColorGrey};
  gap: 14px;
  text-wrap: wrap;

  li {
    font-family: "Gilroy-Regular";
    font-weight: 400;
    font-size: 14px;
    margin: 8px 0;
    font-size: 16px;
    display: flex;
    align-items: center; /* Align image and text */
    gap: 8px; /* Space between bullet and text */
  }
`;

const PlanContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  flex-grow: 1; /* This makes the content area grow to take up available space */
`;

const PriceContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    box-sizing: border-box;
    width: 100%;
    background-color: #fff9f7;
    padding: 16px 30px;
    border-radius: 14px;
    color: ${(props) => props.theme.token.pricingTextColorGrey};
    font-family: "Gilroy-Bold";
    font-size: 18px;
    font-weight: 400;
`;

const StyledButton = styled(Button)`
    background-color: 'transparent';
    color: ${(props) => props.theme.token.pricingTextColorGrey};
    border: 1px solid ${(props) => props.theme.token.colorBlack};
    font-size: 16px;
    font-weight: 400;
    font-family: "Gilroy-Regular";
    border-radius: 200px;
    padding: 16px 30px;
    height: 52px;
    width: 100%;

    ${(props) =>
      !props.disabled &&
      css`
        &:hover {
          background-color: ${props.theme.token.colorPrimary} !important;
          color: #fff !important;
          border: 1px solid ${props.theme.token.colorPrimary} !important;
          font-family: "Gilroy-Bold" !important;
        }
      `}
`;

const CustomText = styled(Text)`
    font-size: 14px;
    font-weight: 400;
    font-family: "Gilroy-Regular";
    color: ${(props) => (props.trialDays === 0 ? props.theme.token.pricingTextColorGrey : 'transparent')};
`;

const PlanCard = ({ title, subtitle, price, credits, bestFor, inclusions, exclusions, trialDays, featured, maxHeight, navigate }) => (
  <PlanCardContainer featured={featured} height={maxHeight}>
    <PlanContent>
    <div style={{ textAlign: "center", gap: "4px", display: "flex", flexDirection: "column" }}>
        <PlanTitle>{title}</PlanTitle>
        {/* <SubText>{subtitle}</SubText> */}
    </div>
    <img src={ASSETS.pricingArrow} alt="pricing-arrow" style={{ width: "29px", height: "auto" }} />
    <PriceContainer>
        <div
          style={{
            gap: "8px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {trialDays === 0 ? (
              <>
          <span
            style={{
              fontSize: "34px",
              fontWeight: "400",
              fontFamily: "Gilroy-Bold",
            }}
          >
                {"₹ "}{price}{" "}
                <span
                  style={{
                    fontSize: "14px",
                    fontWeight: 400,
                    fontFamily: "Gilroy-Medium",
                  }}
                >
                  / mon
                </span>
          </span>
          <span
            style={{
              fontSize: "14px",
              fontWeight: "400",
              fontFamily: "Gilroy-Medium",
              lineHeight: "16.98px",
            }}
          >
            {credits} credits
          </span>
          </>
          ) : (
            <span
              style={{
                fontSize: "34px",
                fontWeight: "400",
                fontFamily: "Gilroy-Bold",
              }}
            >
              Coming Soon
            </span>
          )}
          <span
            style={{
              fontSize: "12px",
              fontWeight: "400",
              fontFamily: "Gilroy-SemiBold",
              lineHeight: "16.98px",
              textAlign: "center",
            }}
          >
            {bestFor}
          </span>
        </div>
    </PriceContainer>
    <FeatureList>
    {inclusions.map((inclusion, index) => (
        <li key={index}>
        <img
            src={ASSETS.pricingTick}
            alt="available"
            style={{ width: "20px", height: "20px" }}
        />
        {inclusion}
        </li>
    ))}
    {/* {exclusions.map((exclusion, index) => (
        <li key={index}>
        <img
            src={ASSETS.pricingCross}
            alt="unavailable"
            style={{ width: "20px", height: "20px" }}
        />
        {exclusion}
        </li>
    ))} */}
    </FeatureList>
    </PlanContent>
    <div
    style={{
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        alignItems: "center",
        justifyContent: "center",
        }}>
    <StyledButton 
        onClick={() => navigate("/signup")}
        disabled={trialDays != 0}
        >
      {trialDays === 0 ? "Sign Up" : `Coming Soon`}
    </StyledButton>
    <CustomText
        trialDays={trialDays}>
        {trialDays === 0 ? "No credit card required" : `Coming Soon`}
    </CustomText>
    </div>
  </PlanCardContainer>
);

const PricingSection = () => {
    const { token } = useToken();
  const [billingType, setBillingType] = useState("Monthly");
  const cardRefs = useRef([]);
  const [maxHeight, setMaxHeight] = useState(0);

  const navigate = useNavigate();

  const plansData = {
    Monthly: [
      {
        title: "Free",
        subtitle: "Gemini",
        price: "0",
        credits: "400",
        bestFor: "For Class 5 - 12",
        inclusions: [
          "Access to CBSE curriculum",
          "Access to NCERT textbooks",
          "Chat with NCERT textbooks (All Subjects)",
          "Unlimited Practice Tests from NCERT textbooks (All Subjects)",
          "Basic Performance Analysis"
        ],
        exclusions: [
          "No full-book or multi-chapter chat/quiz",
          "No quiz pause or question increase",
          "No quiz history",
          "No AI Avatar for language practice"
        ],
        trialDays: 0
      },
      {
        title: "Smart",
        subtitle: "DeepSeek",
        price: "199",
        credits: "3000",
        bestFor: "For Class 5 - 12",
        inclusions: [
          "Everything in Free",
          "Acces to CBSE and ICSE curriculum",
          "Chat with CBSE & ICSE Textbooks (NCERT + Publisher reference textbooks)",
          "Unlimited Practice Tests (NCERT + Publisher Reference textbooks)",
          "Chat & Quiz from any video url",
          "Practice Foreign language",
          "Exam Recommendations",
          "Personalized Performance Analysis and Reports",
          "Mobile Notifications"
        ],
        exclusions: [
          "Post-trial, upgrade or downgrade to Free Prep"
        ],
        featured: true,
        trialDays: 5
      },
      {
        title: "Pro",
        subtitle: "DeepSeek",
        price: "299",
        credits: "6000",
        bestFor: "For Engineering, Medical and UPSC aspiring candidates",
        inclusions: [
          "Everything in Smart Plan",
          "Access to Engineering, Medical, UPSC competition exam curriculum",
          "Chat with Competition Exam Textbooks",
          "Unlimited Practice Tests (Engineering/Medical/UPSC)",
          "Access books from publishers & authors",
          "Upcoming Exam recommendations",
          "Personalized Performance Analysis and Roadmap",
        ],
        exclusions: [
          "Post-trial, upgrade or downgrade to Free Prep"
        ],
        trialDays: 5
      }
    ],
    Yearly: [
      {
        title: "Free",
        subtitle: "Gemini",
        price: "0",
        credits: "400",
        bestFor: "For Class 5 - 12",
        inclusions: [
          "NCERT books only",
          "Chat with single chapters",
          "Unlimited quizzes (5 questions per quiz)",
          "Story narration for language practice"
        ],
        exclusions: [
          "No full-book or multi-chapter chat/quiz",
          "No quiz pause or question increase",
          "No quiz history",
          "No AI Avatar for language practice"
        ],
        trialDays: 0
      },
      {
        title: "Smart",
        subtitle: "DeepSeek",
        price: "2149",
        credits: "3000",
        bestFor: "For Class 5 - 12",
        inclusions: [
          "Chat with textbooks",
          "Unlimited quizzes",
          "Basic performance analysis",
          "Story narration & quizzes for language practice",
          "Chat & quiz from videos",
          "Access books from publishers & authors",
          "Full chat & quiz settings enabled"
        ],
        exclusions: [
          "Post-trial, upgrade or downgrade to Free Prep",
        ],
        featured: true,
        trialDays: 5
      },
      {
        title: "Pro",
        subtitle: "DeepSeek",
        price: "3229",
        credits: "6000",
        bestFor: "For Engineering, Medical and UPSC aspiring candidates",
        inclusions: [
          "Chat with textbooks",
          "Unlimited quizzes",
          "Personalized analysis & reports",
          "Chat & quiz from video URLs",
          "Access books from publishers & authors",
          "Exam paper recommendations",
          "Upload your own books",
          "Comprehensive language practice"
        ],
        exclusions: [
          "Post-trial, upgrade or downgrade to Free Prep"
        ],
        trialDays: 5
      }
    ],
  };

  useEffect(() => {
    setTimeout(() => {
      const heights = cardRefs.current.map((card) => card?.offsetHeight || 0);
      const maxHeight = Math.max(...heights);
      setMaxHeight(maxHeight);
    }, 100);
  }, [billingType]);

  return (
    <SectionContainer>
      <HeaderSection>
        <Title
          style={{
            fontSize: "48px",
            fontWeight: 400,
            fontFamily: "Gilroy-Bold",
            marginBottom: "16px",
            ...(window.innerWidth <= 768 && { fontSize: "36px" }),
          }}
        >
          Which one's suitable for you?
        </Title>
        <Text
          style={{
            fontSize: "18px",
            fontFamily: "Gilroy-Regular",
            fontWeight: 400,
            color: "#7d7d7d",
            maxWidth: "600px",
          }}
        >
          Unlock Your Potential with Affordable Plans
        </Text>
        {/* <div style={{ marginTop: "24px" }}>
          <ButtonLeft
            onClick={() => setBillingType("Monthly")}
            style={{
                backgroundColor: billingType === "Monthly" ? token.colorPrimary : 'transparent',
                color: billingType === "Monthly" ? "#FFFFFF" : token.pricingTextColorGrey,
            }}>
            Monthly
          </ButtonLeft>
          <ButtonRight
            onClick={() => setBillingType("Yearly")}
            style={{
                backgroundColor: billingType === "Yearly" ? token.colorPrimary : 'transparent',
                color: billingType === "Yearly" ? "#FFFFFF" : token.pricingTextColorGrey,
            }}
          >
            Yearly
          </ButtonRight>
        </div> */}
      </HeaderSection>
      <PlanContainer>
        {plansData[billingType].map((plan, index) => (
          <div key={index} ref={(el) => (cardRefs.current[index] = el)}>
            <PlanCard
              key={index}
              title={plan.title}
              subtitle={plan.subtitle}
              price={plan.price}
              credits={plan.credits}
              bestFor={plan.bestFor}
              inclusions={plan.inclusions}
              exclusions={plan.exclusions}
              trialDays={plan.trialDays}
              featured={plan.featured}
              maxHeight={maxHeight}
              navigate={navigate}
            />
          </div>
        ))}
      </PlanContainer>
    </SectionContainer>
  );
};

export default PricingSection;
