import { Row, Col, Typography, Flex, Progress, theme } from "antd";
import { PlayCircleOutlined } from "@ant-design/icons";
import { QuizInfoCard, QuizSettingsIcon } from "../../components";
import { ASSETS } from "../../constants";

const { useToken } = theme;
const { Title, Text } = Typography;

function QuizInfoCardsSection({
  difficulty,
  timeLeft,
  questionsAnswered,
  totalQuestions,
  onPauseResume,
  onRestart,
  onStop,
  isPaused,
}) {
  const { token } = useToken();
  const progressPercentage = Math.round(
      (questionsAnswered / totalQuestions) * 100
    )

  return (
    <Flex
      style={{
        backgroundColor: token.colorAccent,
        padding: "20px",
        borderRadius: "10px",
        height: "89px",
        justifyContent: "space-between",
      }}
    >
      <Flex vertical>
        <Text
          style={{
            color: token.logoBlue,
            fontFamily: "Gilroy-Bold",
            fontSize: "24px",
            fontWeight: 400,
            lineHeight: "29.71px",
          }}
        >
          {timeLeft}
        </Text>
        <Text
          style={{
            color: token.logoBlue,
            fontFamily: "Gilroy-Medium",
            fontSize: "12px",
            fontWeight: 400,
            lineHeight: "14.56px",
          }}
        >
          Difficulty: {difficulty}
        </Text>
      </Flex>
      <Flex
        style={{
          gap: "10px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          }}>
        <QuizSettingsIcon
          icon={
            isPaused ? (
              <img src={ASSETS.playQuizIcon} alt="play" />
            ) : (
              <img src={ASSETS.pauseQuizIcon} alt="pause" />
            )
          }
          tooltipTitle={isPaused ? "Resume Quiz" : "Pause Quiz"}
          onClick={onPauseResume}
          border={token.border}
        />
        <QuizSettingsIcon
          icon={<img src={ASSETS.restartQuizIcon} alt="restart" />}
          tooltipTitle={"Restart Quiz"}
          onClick={onRestart}
          border={token.border}
        />
        <QuizSettingsIcon
          icon={<img src={ASSETS.cancelQuizIcon} alt="stop" />}
          tooltipTitle={"Stop Quiz"}
          onClick={onStop}
          border={token.border}
        />
      </Flex>
      <Flex vertical
        style={{
          width: "50%",
          gap: "6px",
        }}>
        <Flex
          style={{
            justifyContent: "space-between",
            }}>
          <Text
            style={{
              color: token.logoBlue,
              fontFamily: "Gilroy-SemiBold",
              fontSize: "14px",
              fontWeight: 400,
              lineHeight: "17.15px",
            }}
          >
            Progress
          </Text>
          <Text
            style={{
              color: token.logoBlue,
              fontFamily: "Gilroy-SemiBold",
              fontSize: "16px",
              fontWeight: 400,
              lineHeight: "19.6px",
            }}
          >
            {`${questionsAnswered}/${totalQuestions}`}
          </Text>
        </Flex>
        <div
          style={{
            position: "relative",
            width: "100%",
            height: "4px",
            backgroundColor: token.colorLightGrey2,
            borderRadius: "2px",
          }}
        >
          <div
            style={{
              width: `${progressPercentage}%`,
              height: "100%",
              backgroundColor: token.colorPrimary,
              borderRadius: "2px",
              position: "absolute",
              transition: "width 0.3s ease-in-out",
            }}
          />
          <div
            style={{
              width: "12px",
              height: "12px",
              backgroundColor: token.colorPrimary,
              borderRadius: "50%",
              position: "absolute",
              top: "50%",
              left: `${progressPercentage}%`,
              transform: "translate(-50%, -50%)",
              transition: "left 0.3s ease-in-out",
            }}
          />
        </div>
      </Flex>
    </Flex>
  );
}

export default QuizInfoCardsSection;
