import React, { useState, useEffect, useRef } from "react";
import {
  Flex,
  Layout,
  Typography,
  Pagination,
  Button,
  theme,
  Form,
  Input,
  message,
} from "antd";
import { QuizInfoCardsSection, QuizOptionCard } from "../../components";
import { useNavigate } from "react-router-dom";
import { evaluateQuiz } from "../../services/quiz-service";
import { renderMarkdownWithLatex } from "../../utils/helpers/latex-parser-helper";


const { useToken } = theme;
const { Content } = Layout;
const { Text } = Typography;

function QuizDisplay({
  quizType,
  difficulty,
  numQuestions,
  allQuestions,
  quizData,
  onQuizEnd,
}) {
  const { token } = useToken();
  const [quizTime, setQuizTime] = useState(0);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [questionsAnswered, setQuestionsAnswered] = useState(0);
  const [answeredQuestions, setAnsweredQuestions] = useState(
    Array(quizData?.quiz?.questions?.length || 0).fill(null)
  );
  const [isPaused, setIsPaused] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [timerId, setTimerId] = useState(null);
  const [currentSkippedQuestions, setCurrentSkippedQuestions] = useState([]); // Tracks current skips for quiz flow
  const [skippedQuestionsForResults, setSkippedQuestionsForResults] = useState(
    []
  ); // Tracks total skipped for results
  const contentRef = useRef(null);
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    console.log("Quiz Data:", quizData);	
    if (!isPaused && !isSubmitted) {
      const id = setInterval(() => {
        setQuizTime((prevTime) => prevTime + 1);
      }, 1000);
      setTimerId(id);
      return () => clearInterval(id);
    } else {
      clearInterval(timerId);
    }
  }, [isPaused, isSubmitted]);

  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins.toString().padStart(2, "0")}:${secs
      .toString()
      .padStart(2, "0")}`;
  };

  const handlePageChange = (page) => {
    setCurrentQuestionIndex(page - 1);
  };

  const handleSkipQuestion = (questionIndex) => {
    if (!isSubmitted && !currentSkippedQuestions.includes(questionIndex)) {
      setCurrentSkippedQuestions((prev) => [...prev, questionIndex]);
      setSkippedQuestionsForResults((prev) => [...prev, questionIndex]);

      setAnsweredQuestions((prev) => {
        const newAnswered = [...prev];
        newAnswered[questionIndex] = "skipped";
        return newAnswered;
      });

      // Automatically move to the next question
      setCurrentQuestionIndex((prevIndex) =>
        prevIndex < quizData.length - 1
          ? prevIndex + 1
          : currentSkippedQuestions[0]
      );
    }
  };

  const handleAnswerSelect = (questionIndex, optionIndex) => {
    if (!isSubmitted) {
      const questionType = quizData.quiz.questions[questionIndex].question_type;

      setAnsweredQuestions((prev) => {
        const newAnswered = [...prev];
        newAnswered[questionIndex] = {
          question_id: quizData.quiz.questions[questionIndex].id,
          answer: ["mcq", "true_false"].includes(questionType)
            ? String.fromCharCode(97 + optionIndex)
            : optionIndex, // For fill_blanks and subjective, use the answer directly
        };

        // Only increment questionsAnswered if this is a new answer
        if (prev[questionIndex] === null) {
          setQuestionsAnswered((prevCount) => prevCount + 1);
        }

        return newAnswered;
      });

      // Only move to next question in single question view mode
      if (!allQuestions) {
        goToNextQuestion();
      }
    }
  };

  const goToNextQuestion = () => {
    if (currentQuestionIndex < quizData.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else if (currentSkippedQuestions.length > 0) {
      const nextSkippedQuestion = currentSkippedQuestions.shift();
      setCurrentQuestionIndex(nextSkippedQuestion);
      setCurrentSkippedQuestions([...currentSkippedQuestions]);
    }
  };

  const handlePauseResume = () => {
    setIsPaused((prev) => !prev);
  };

  const handleRestart = () => {
    setQuestionsAnswered(0);
    setQuizTime(0);
    setCurrentQuestionIndex(0);
    setAnsweredQuestions(Array(quizData?.quiz?.questions?.length || 0).fill(null));
    setIsPaused(false);
    setIsSubmitted(false);
    setCurrentSkippedQuestions([]);
  };

  const handleStop = () => {
    onQuizEnd();
  };

  const handleSubmitQuiz = async () => {
    setIsSubmitting(true);
    setIsSubmitted(true);
    try {
      const formattedAnswers = answeredQuestions
        .filter((answer) => answer !== null && answer !== "skipped")
        .map((answer, index) => {
          const questionData = {
            question_id: answer.question_id,
            answer: answer.answer,
          };

          console.log("Quiz Type: ", quizType);
          if (quizType[0].backend === "true_false") {
            if (answer.answer === 'a') {
              console.log("Answer is true");
              questionData.answer = 'true';
            } else if (answer.answer === 'b') {
              console.log("Answer is false");
              questionData.answer = 'false';
            }
          }

          // Add type field if quiz type is combined
          if (quizType === "combined") {
            questionData.type = quizData.quiz.questions[index].question_type;
          }

          return questionData;
        });

      const authToken = localStorage.getItem("authToken");
      const requestBody = {
        quiz_id: quizData.quiz.id,
        answers: formattedAnswers,
      };

      // Call evaluateQuiz service with formatted body
      const evaluationResult = await evaluateQuiz(requestBody, authToken);
      
      console.log("State: ", {
        quizData,
        evaluationResult,
        userAnswers: formattedAnswers,
        timeElapsed: formatTime(quizTime),
        skippedQuestions: skippedQuestionsForResults,
      }
      )
      navigate("/result", {
        state: {
          quizData,
          evaluationResult,
          userAnswers: formattedAnswers,
          timeElapsed: formatTime(quizTime),
          skippedQuestions: skippedQuestionsForResults,
        },
      });
    } catch (error) {
      message.error("Failed to evaluate quiz: " + error.message);
      setIsSubmitted(false);
    } finally {
      setIsSubmitting(false);
    }
  };

  const allQuestionsAnswered = answeredQuestions.every((q) => q !== null);  

  const renderQuestionContent = (quiz, questionIndex) => {
    switch (quiz.question_type) {
      case "mcq":
        return (
          <Flex vertical style={{ width: "100%" }}>
            {quiz.options.map((option, optIdx) => (
              <QuizOptionCard
                key={optIdx}
                optionNumber={String.fromCharCode(65 + optIdx)}
                text={renderMarkdownWithLatex(option)}
                onClick={() => handleAnswerSelect(questionIndex, optIdx)}
                highlighted={
                  answeredQuestions[questionIndex]?.answer ===
                  String.fromCharCode(97 + optIdx)
                }
              />
            ))}
          </Flex>
        );

      case "true_false":
        return (
          <Flex vertical style={{ width: "100%" }}>
            {["True", "False"].map((option, optIdx) => (
              <QuizOptionCard
                key={optIdx}
                optionNumber={String.fromCharCode(65 + optIdx)}
                text={renderMarkdownWithLatex(option)}
                onClick={() => handleAnswerSelect(questionIndex, optIdx)}
                highlighted={
                  answeredQuestions[questionIndex]?.answer ===
                  String.fromCharCode(97 + optIdx)
                }
              />
            ))}
          </Flex>
        );

      case "fill_blanks":
        return (
          <Form.Item style={{ marginTop: 16 }}>
            <Input
              size="large"
              placeholder="Type your answer"
              value={answeredQuestions[questionIndex]?.answer || ""}
              onChange={(e) =>
                handleAnswerSelect(questionIndex, e.target.value)
              }
              disabled={isSubmitted}
            />
          </Form.Item>
        );

      case "subjective":
        return (
          <Form.Item style={{ marginTop: 16 }}>
            <Input.TextArea
              size="large"
              rows={6}
              placeholder="Type your answer"
              value={answeredQuestions[questionIndex]?.answer || ""}
              onChange={(e) =>
                handleAnswerSelect(questionIndex, e.target.value)
              }
              disabled={isSubmitted}
            />
          </Form.Item>
        );

      default:
        return <Text>Oops! Something went wrong.</Text>;
    }
  };

  return (
    <Layout
      style={{
        width: "100%",
        height: "100%",
        borderBottomLeftRadius: "16px",
        borderBottomRightRadius: "16px",
        backgroundColor: token.colorWhite,
      }}
    >
      <Content
        ref={contentRef}
        style={{
          padding: "0px 12px 12px 0px",
          display: "flex",
          flexDirection: "column",
          width: "100%",
          overflowY: "auto",
        }}
      >
        <div
          style={{
            position: "sticky",
            top: 0,
            zIndex: 1000,
            marginBottom: 24,
          }}
        >
          <QuizInfoCardsSection
            difficulty={difficulty}
            timeLeft={formatTime(quizTime)}
            questionsAnswered={questionsAnswered}
            totalQuestions={quizData?.quiz?.questions?.length || 0}
            onPauseResume={handlePauseResume}
            onRestart={handleRestart}
            onStop={handleStop}
            isPaused={isPaused}
          />
        </div>

        <Flex
          vertical
          style={{ marginTop: 10, marginLeft: "12px", marginRight: "12px" }}
        >
          {allQuestions ? (
            quizData.quiz.questions.map((quiz, idx) => (
              <Flex key={idx} vertical style={{ marginBottom: 24 }}>
                <Flex align="center" style={{ marginBottom: 12 }}>
                  <Typography.Text
                    style={{
                      margin: 0,
                      fontWeight: 500,
                      fontFamily: "Gilroy-SemiBold",
                      fontSize: "14px",
                      color: token.logoBlue,
                    }}
                  >
                    {idx + 1}. {renderMarkdownWithLatex(quiz.question_text)}
                  </Typography.Text>
                </Flex>
                {renderQuestionContent(quiz, idx)}
              </Flex>
            ))
          ) : (
            <>
              <Flex key={currentQuestionIndex} vertical>
                <Flex align="center" style={{ marginBottom: 12 }}>
                  <Typography.Paragraph
                    style={{
                      margin: 0,
                      fontWeight: 500,
                      fontFamily: "Gilroy-SemiBold",
                      fontSize: "14px",
                      color: token.logoBlue,
                    }}
                  >
                    {currentQuestionIndex + 1}.{" "}
                    {
                      renderMarkdownWithLatex(quizData.quiz.questions[currentQuestionIndex]
                        .question_text)
                    }
                  </Typography.Paragraph>
                </Flex>
                {renderQuestionContent(
                  quizData.quiz.questions[currentQuestionIndex],
                  currentQuestionIndex
                )}
              </Flex>
              <Flex justify="space-between" style={{ marginTop: 12 }}>
                <Button
                  type="primary"
                  onClick={handleSubmitQuiz}
                  disabled={!allQuestionsAnswered}
                  loading={isSubmitting}
                >
                  Submit Quiz
                </Button>
                <Pagination
                  align="end"
                  current={currentQuestionIndex + 1}
                  total={numQuestions}
                  pageSize={1}
                  onChange={handlePageChange}
                />
              </Flex>
            </>
          )}
        </Flex>

        {allQuestions && (
          <Button
            type="primary"
            size="large"
            style={{ marginBottom: 16, display: "block", marginLeft: "auto" }}
            onClick={handleSubmitQuiz}
            disabled={!allQuestionsAnswered}
            loading={isSubmitting}
          >
            <span
              style={{
                fontFamily: "Gilroy-Medium",
                marginLeft: isSubmitting ? "12px" : "0px",
              }}
            >
              Submit Quiz
            </span>
          </Button>
        )}
      </Content>
    </Layout>
  );
}

export default QuizDisplay;
