import ReactMarkdown from "react-markdown";
import { InlineMath, BlockMath } from "react-katex";
import remarkMath from "remark-math";
import rehypeKatex from "rehype-katex";
import "katex/dist/katex.min.css";
import 'katex/contrib/mhchem/mhchem';
import Latex from 'react-latex-next';

const renderMarkdownWithLatex = (text) => {
  const processedContent = text
    // Replace special environments with aligned inside display math delimiters
    // .replace(/\\begin{(align|aligned|gather|eqnarray|gathered|alignedat)}/g, '$$\n\\begin{aligned}')
    // .replace(/\\end{(align|aligned|gather|eqnarray|gathered|alignedat)}/g, '\\end{aligned}\n$$')
    // .replace(/\\begin{matrix\*?}/g, '$$\n\\begin{matrix}')
    // .replace(/\\end{matrix\*?}/g, '\\end{matrix}\n$$')
    // .replace(/\\begin{pmatrix\*?}/g, '$$\n\\begin{pmatrix}')
    // .replace(/\\end{pmatrix\*?}/g, '\\end{pmatrix}\n$$')
    // .replace(/\\begin{bmatrix\*?}/g, '$$\n\\begin{bmatrix}')
    // .replace(/\\end{bmatrix\*?}/g, '\\end{bmatrix}\n$$')
    // .replace(/\\begin{vmatrix\*?}/g, '$$\n\\begin{vmatrix}')
    // .replace(/\\end{vmatrix\*?}/g, '\\end{vmatrix}\n$$')
    // .replace(/\\begin{Vmatrix\*?}/g, '$$\n\\begin{Vmatrix}')
    // .replace(/\\end{Vmatrix\*?}/g, '\\end{Vmatrix}\n$$')
    // .replace(/\\begin{smallmatrix\*?}/g, '$$\n\\begin{smallmatrix}')
    // .replace(/\\end{smallmatrix\*?}/g, '\\end{smallmatrix}\n$$')
    // .replace(/\\begin{cases\*?}/g, '$$\n\\begin{cases}')
    // .replace(/\\end{cases\*?}/g, '\\end{cases}\n$$')
    // .replace(/\\begin{dcases\*?}/g, '$$\n\\begin{dcases}')
    // .replace(/\\end{dcases\*?}/g, '\\end{dcases}\n$$')
    // .replace(/\\begin{rcases\*?}/g, '$$\n\\begin{rcases}')
    // .replace(/\\end{rcases\*?}/g, '\\end{rcases}\n$$')

    // Convert LaTeX inline math delimiters \( ... \) to $ ... $
    .replace(/\\\(/g, '$')
    .replace(/\\\)/g, '$')
    // Convert LaTeX display math delimiters \[ ... \] to $$ ... $$
    .replace(/\\\[/g, '$$')
    .replace(/\\\]/g, '$$');

  return (
    <ReactMarkdown
      remarkPlugins={[remarkMath]}
      rehypePlugins={[
        [
          rehypeKatex,
          {
            trust: true,
            strict: false,
            macros: {
              '\\ce': '\\text{#1}' // Basic mhchem support
            }
          }
        ]
      ]}
      components={{
        // Override paragraph rendering to use a <span> instead of <p>
        p: ({ node, children }) => <span>{children}</span>,
        math: ({ node, inline, ...props }) => <BlockMath {...props} />,
        inlineMath: ({ node, ...props }) => <InlineMath {...props} />,
        code: ({ node, inline, className, children, ...props }) => (
          <code className={className} {...props}>
            {children}
          </code>
        )
      }}
    >
      {processedContent}
    </ReactMarkdown>
  );
};

export { renderMarkdownWithLatex };
