import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Typography, Card, Button, Flex, message, theme } from "antd";
import styled from "styled-components";
import { DeleteOutlined } from "@ant-design/icons";
import ConfirmModal from "../../../components/modals/confirm-modal";
import { deleteAccount } from "../../../services/user-service";
import { getAuth, logout } from "../../../utils";
import { ASSETS } from "../../../constants";

const { Text } = Typography;
const { useToken } = theme;

// Styled Components
const StyledCard = styled(Card)`
  border-radius: 8px;
  background-color: #FFEEEE;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

const Label = styled(Text)`
  font-size: 16px;
  font-weight: 400;
  font-family: Gilroy-SemiBold;
  display: block;
`;

const Subtitle = styled(Text)`
  font-size: 12px;
  font-weight: 400;
  font-family: Gilroy-Regular;
  color: ${(props) => props.theme.token.colorBlack};
  display: block;
`;

const DeleteAccount = () => {
  const { token } = useToken();
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleDelete = async () => {
    try {
      const { token } = getAuth();
      await deleteAccount(token);
      message.success("Account deleted successfully");
      logout(navigate);
    } catch (error) {
      message.error("Failed to delete account");
    }
    setIsModalVisible(false);
  };

  return (
    <div
      id="delete-account"
      style={{ marginTop: "12px", marginBottom: "24px" }}
    >
      <StyledCard bordered={false} bodyStyle={{ padding: 0 }}>
        <Flex justify="space-between" align="center">
          <div style={{ gap: "6px" }}>
            <Label>Delete My Account</Label>
            <Subtitle>
              Deleting your account will result in the loss of your chat
              history, reading history, and test details. Please proceed with
              caution.
            </Subtitle>
          </div>
          <Button
            type="ghost"
            shape="circle"
            size="large"
            danger
            style={{ marginLeft: "16px", flexShrink: 0 }}
            onClick={() => setIsModalVisible(true)}
          >
            <img src={ASSETS.trashIconSettings} alt="Delete Account" style={{width: "24px", height: "auto"}} />
          </Button>
        </Flex>
      </StyledCard>

      <ConfirmModal
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        title="Are You Sure You Want to Delete Your Account?"
        subtitle="Deleting your account will permanently erase your chat history, reading history, and test details. Please confirm if you wish to proceed."
        secondaryButtonText="Yes, Remove"
        primaryButtonText="No, Go Back"
        onSecondaryButton={handleDelete}
        onPrimaryButton={() => setIsModalVisible(false)}
      />
    </div>
  );
};

export default DeleteAccount;
