import React, { useState, useRef, useEffect } from "react";
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Typography, theme } from "antd";
import styled from "styled-components";
import { ASSETS } from "../constants";

const { Title, Text } = Typography;
const { useToken } = theme;

const HeaderSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const DescriptionText = styled(Text)`
  max-width: 600px;
  text-align: center;
`;

const CarouselContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ScrollWrapper = styled.div`
  position: relative;
  padding: 40px 0;
  width: 100%;
  overflow: visible;
`;

const ScrollContainer = styled.div`
  display: flex;
  transition: transform 0.3s ease-in-out;
  will-change: transform;
  gap: 40px;
`;

const StyledCard = styled.div`
  flex: 0 0 ${(props) => (props.isActive ? "360px" : "300px")};
  height: ${(props) => (props.isActive ? "280px" : "260px")};
  margin: 0 10px;
  border-radius: 22.29px;
  box-shadow: 0px 0px 111.45px 0px #0000000f;
  background: #fff;
  padding: 20px;
  box-sizing: border-box;
  transform: ${(props) => (props.isActive ? "scale(1.1)" : "scale(1)")};
  opacity: ${(props) => (props.isActive ? 1 : 0.5)};
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const QuoteGraphic = styled.img`
  height: 18px;
  margin-bottom: 10px;
`;

const TestimonialText = styled.div`
  max-height: 100px;
  overflow-y: auto;
  font-size: 15px;
  line-height: 18px;
  font-weight: 400;
  font-family: 'Gilroy-Regular';
  color: ${(props) => props.theme.token.colorBlack};
  margin-bottom: 22.29px; /* Gap between text and user info */
  -ms-overflow-style: none; /* Hide scrollbar for IE and Edge */
  scrollbar-width: none; /* Hide scrollbar for Firefox */
  &::-webkit-scrollbar {
    display: none; /* Hide scrollbar for Chrome, Safari, and Edge */
  }
`;

const UserInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const UserDetails = styled.div`
  h4 {
    margin: 0;
    font-size: 18px;
  }
  p {
    margin: 2px 0 0 0;
    font-size: 13px;
    color: gray;
  }
`;

const Pagination = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 16px;
  gap: 8px;
  align-items: center;
`;

const Dot = styled.div`
  width: ${(props) => (props.isActive ? "20px" : "10px")};
  height: 10px;
  background-color: ${(props) => props.theme.token.colorPrimary};
  opacity: ${(props) => (props.isActive ? 1 : 0.4)};
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
`;

const ArrowButton = styled.div`
  cursor: pointer;
  font-size: 14px;
  color: ${(props) => props.theme.token.colorBlack};
  &:hover {
    opacity: 0.7;
  }
`;

const HeaderWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const HatGraphic = styled.img`
  position: absolute;
  width: 72px;
  height: auto;
  left: 10%;
  top: 50%;
  transform: translateY(-50%);

  @media (max-width: 768px) {
    width: 50px;
    left: -5px;
  }
`;

const ShineGraphic = styled.img`
  position: absolute;
  width: 33px;
  height: 33px;
  right: 10%;
  top: 0;
  transform: rotate(180deg);

  @media (max-width: 768px) {
    right: calc(50% - 160px);
  }
`;

const TestimonialSection = () => {
  const token = useToken();
  const testimonials = [
    {
      name: "Aditia Roy",
      designation: "Student",
      text: "GradeMakers has transformed how I study! I can now ask my textbooks questions and get clear answers instantly. Preparing for exams feels like a fun challenge instead of a chore. The practice tests are like a game—every time I see my scores improve, I get more motivated to keep going!",
    },
    {
      name: "Alisha P.",
      designation: "Student",
      text: "Practicing a foreign language with the AI bots feels like talking to a friend! It’s so realistic and fun. I’ve improved my French accent so much in just a few weeks.",
    },
    {
      name: "Anonymous",
      designation: "Parent",
      text: "GradeMakers is great learning & practice tool for my children. Its fun to chat with the textbooks.",
    },
    {
      name: "Priya S.",
      designation: "Parent",
      text: "GradeMakers gives us detailed reports that help us understand our child’s strengths and weaknesses. It’s not just about scores; it’s about meaningful insights. I wish I had this tool when I was a student!",
    },
    {
      name: "Neha L.",
      designation: "Parent",
      text: "As working parents, it’s hard to guide our child every day. GradeMakers fills that gap wonderfully. The AI explanations are simple yet effective, and the instant evaluations save so much time.",
    },
    {
      name: "Anita R.",
      designation: "Teacher",
      text: "The AI is surprisingly accurate and effective in explaining complex concepts. Students come to class better prepared, and I spend less time on repetitive questions. It’s a great way to enhance blended learning.",
    },
    {
      name: "Rita M.",
      designation: "Principal",
      text: "The technology behind GradeMakers is revolutionary. It addresses the unique needs of every student, ensuring no one is left behind. It’s the perfect blend of innovation and education.",
    },
  ];

  const [activeIndex, setActiveIndex] = useState(5); // Start at the first "real" card in the middle
  const scrollRef = useRef(null);

  // Clone the cards for infinite scrolling
  const extendedTestimonials = [
    ...testimonials.slice(-3), // Last two cards
    ...testimonials, // Original cards
    ...testimonials.slice(0, 3), // First two cards
  ];

  useEffect(() => {
    centerCard(activeIndex);
  }, [activeIndex]);

  useEffect(() => {
    if (scrollRef.current) {
      // Reset scroll position when reaching clones
      const totalCards = extendedTestimonials.length;
      if (activeIndex === 1) {
        setTimeout(() => {
          setActiveIndex(totalCards - 4);
          scrollRef.current.style.transition = "none";
          centerCard(totalCards - 4);
        }, 300);
      } else if (activeIndex === totalCards - 3) {
        setTimeout(() => {
          setActiveIndex(3);
          scrollRef.current.style.transition = "none";
          centerCard(3);
        }, 300);
      } else {
        scrollRef.current.style.transition = "transform 0.3s ease-in-out";
      }
    }
  }, [activeIndex, extendedTestimonials.length]);

  const cardWidth = 360; // Approximate card width including margin for center alignment

  const centerCard = (index) => {
    const offset = index * cardWidth - (scrollRef.current.offsetWidth - cardWidth) / 2;
    if (scrollRef.current) {
      scrollRef.current.style.transform = `translateX(-${offset}px)`;
    }
  };

  const handleCardClick = (index) => {
    setActiveIndex(index);
  };

  const handleDotClick = (index) => {
    const mappedIndex = index + 2; // Offset for the cloned cards
    setActiveIndex(mappedIndex);
  };

  const handleNext = () => {
    setActiveIndex((prevIndex) => (prevIndex + 1) % extendedTestimonials.length);
  };

  const handlePrev = () => {
    setActiveIndex((prevIndex) => (prevIndex - 1 + extendedTestimonials.length) % extendedTestimonials.length);
  };

  return (
    <div style={{ marginBottom: "60px" }}>
      <HeaderWrapper>
        <HatGraphic src={ASSETS.testimonialsHatGraphic} alt="Hat graphic" />
        <ShineGraphic src={ASSETS.shineThreeLinesGraphic} alt="Shine graphic" />
        <HeaderSection>
          <Title
            style={{
              fontSize: "48px",
              fontWeight: 400,
              fontFamily: 'Gilroy-Bold',
              marginBottom: "16px",
              "@media (max-width: 768px)": {
                fontSize: "36px",
              },
            }}
          >
            Testimonials and Review
          </Title>
          <DescriptionText
            style={{
              fontSize: "18px",
              fontWeight: 400,
              fontFamily: 'Gilroy-Regular',
              color: token.textColorGrey,
            }}
          >
            Here is what Students, Parents & Teachers are saying about GradeMakers!
          </DescriptionText>
        </HeaderSection>
      </HeaderWrapper>

      <CarouselContainer>
        <ScrollWrapper>
          <ScrollContainer ref={scrollRef}>
            {extendedTestimonials.map((testimonial, index) => (
              <StyledCard
                key={index}
                isActive={index === activeIndex}
                onClick={() => handleCardClick(index)}
              >
                <div style={{ justifyContent: "center" }}>
                <QuoteGraphic src="/images/quote-graphic.svg" alt="quote" />
                <TestimonialText>{testimonial.text}</TestimonialText>
                </div>
                
                <UserInfo>
                  <img
                    src={`https://doodleipsum.com/100x100/avatar?shape=circle&bg=lightgray&n=${
                      (index % testimonials.length) + 1
                    }`}
                    alt="avatar"
                    style={{
                      width: "56px",
                      height: "56px",
                      borderRadius: "50%",
                    }}
                  />
                  <UserDetails>
                    <h4 style={{fontFamily: 'Gilroy-Bold', fontWeight: '400px', fontSize: '18px', color: '#145365'}}>{testimonial.name}</h4>
                    <p style={{fontFamily: 'Gilroy-Regular', fontWeight: '400px', fontSize: '13px', color: '#145365'}}>{testimonial.designation}</p>
                  </UserDetails>
                </UserInfo>
              </StyledCard>
            ))}
          </ScrollContainer>
        </ScrollWrapper>

        <Pagination>
          <ArrowButton onClick={handlePrev}>
            <LeftOutlined />
          </ArrowButton>
          {testimonials.map((_, index) => (
            <Dot
              key={index}
              isActive={index + 2 === activeIndex}
              onClick={() => handleDotClick(index)}
            />
          ))}
          <ArrowButton onClick={handleNext}>
            <RightOutlined />
          </ArrowButton>
        </Pagination>
      </CarouselContainer>
    </div>
  );
};

export default TestimonialSection;
