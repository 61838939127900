const BASE_URL = `${process.env.PUBLIC_URL}`;

const ASSETS = {
  // Images
  logo: `${BASE_URL}/images/grademakers-tm-logo.svg`,
  shortLogo: `${BASE_URL}/images/short-logo.svg`,

  // Auth
  googleLogo: `${BASE_URL}/images/google-logo-auth-screens.svg`,
  facebookLogo: `${BASE_URL}/images/facebook-logo-auth-screens.svg`,
  csBookCard: `${BASE_URL}/images/cs-book-card-auth-screens.svg`,
  laBookCard: `${BASE_URL}/images/la-book-card-auth-screens.svg`,
  phyBookCard: `${BASE_URL}/images/phy-book-card-auth-screens.svg`,
  waveHand: `${BASE_URL}/images/wave-hand-auth-screens.png`,
  termsAndConditions: `${BASE_URL}/files/terms-and-conditions.html`,

  // Drawer icons
  dashboardDrawerIcon: `${BASE_URL}/images/dashboard-drawer-icon.svg`,
  reportsDrawerIcon: `${BASE_URL}/images/reports-drawer-icon.svg`,
  askYourBookDrawerIcon: `${BASE_URL}/images/ask-your-book-drawer-icon.svg`,
  takeATestDrawerIcon: `${BASE_URL}/images/take-a-test-drawer-icon.svg`,
  langPracticeDrawerIcon: `${BASE_URL}/images/lang-practice-drawer-icon.svg`,
  settingsDrawerIcon: `${BASE_URL}/images/settings-drawer-icon.svg`,
  notificationsDrawerIcon: `${BASE_URL}/images/notifications-drawer-icon.svg`,

  // Header
  waveHandAuthScreens: `${BASE_URL}/images/wave-hand-auth-screens.png`,
  searchRightArrow: `${BASE_URL}/images/search-right-arrow.svg`,
  notificationsHeaderIcon: `${BASE_URL}/images/notifications-header-icon.svg`,

  // Dashboard Sider
  dashboardPremiumCardBackground: `${BASE_URL}/images/dashboard-premium-card-background.svg`,
  dashboardPremiumCardHeader: `${BASE_URL}/images/dashboard-premium-card-header.svg`,
  dashboardPremiumImage: `${BASE_URL}/images/premium-image.svg`,

  // Book
  chapterIcon: `${BASE_URL}/images/chapter-icon.svg`,
  bookIcon: `${BASE_URL}/images/book-icon.svg`,
  // Chat
  chatInitialBookIcon: `${BASE_URL}/images/chat-initial-book-icon.svg`,

  // Animations
  welcomeWave: `${BASE_URL}/animations/wave-welcome-screen.gif`,
  dashboardPremiumCardAnimation: `${BASE_URL}/animations/dashboard-premium-card-animation.gif`,
  landingStudentAnimation: `${BASE_URL}/animations/landing-student-graphic.json`,
  // aiWorksSectionAnimation: `${BASE_URL}/animations/ai-works-section-graphic.gif`,
  aiWorksSectionAnimation: `${BASE_URL}/animations/ai-works-section-animation.json`,
  loginPageAnimation: `${BASE_URL}/animations/auth-left-animation.json`,
  landingElementsAnimation: `${BASE_URL}/animations/hero-elements-animation.lottie`,
  aplusAnimation: `${BASE_URL}/animations/aplus-animation.lottie`,
  paperPlaneAnimation: `${BASE_URL}/animations/paper-plane-animation.json`,

  // Landing Page
  // Hero Section
  shineThreeLinesGraphic: `${BASE_URL}/images/shine-three-lines.svg`,
  studentGraphic: `${BASE_URL}/images/student-graphic.png`,
  aplusText: `${BASE_URL}/images/aplus-text.svg`,
  aiPoweredAnimation: `${BASE_URL}/animations/ai-powered-anim.json`,

  // We've got you covered section
  coveredArrow: `${BASE_URL}/images/covered-arrow.svg`,
  coveredCircleTextGraphic: `${BASE_URL}/images/covered-circle-text-graphic.svg`,
  coveredTextLeftGraphic: `${BASE_URL}/images/covered-text-left-graphic.svg`,

  // AI Works Section
  aiWorksSectionGraphic: `${BASE_URL}/images/ai-works-section-graphic.svg`,
  talkToTextbooks: `${BASE_URL}/images/talk-to-your-textbooks-image.png`,
  practiceMakesPerformer: `${BASE_URL}/images/practice-makes-performer-image.png`,
  realTimeAnalytics: `${BASE_URL}/images/real-time-analytics-image.png`,
  practiceForeignLanguage: `${BASE_URL}/images/practice-foreign-language-image.png`,
  practiceTick: `${BASE_URL}/images/practice-tick.svg`,

  // Pricing Section
  pricingArrow: `${BASE_URL}/images/pricing-arrow.svg`,
  thinStar: `${BASE_URL}/images/thin-star.svg`,
  thickStar: `${BASE_URL}/images/thick-star.svg`,
  pricingTick: `${BASE_URL}/images/pricing-tick.svg`,
  pricingCross: `${BASE_URL}/images/pricing-cross.svg`,

  // Testimonial Section
  testimonialsHatGraphic: `${BASE_URL}/images/testimonials-hat-graphic.svg`,

  // Card Section
  cardSectionBackground: `${BASE_URL}/images/card-section-background.svg`,

  //Auth Screens
  authScreenBackground: `${BASE_URL}/images/auth-screen-background.svg`,

  // Quiz Settings
  categoryQuizSettings: `${BASE_URL}/images/category-quiz-settings.svg`,
  durationQuizSettings: `${BASE_URL}/images/duration-quiz-settings.svg`,
  shuffleAdditionalSettings: `${BASE_URL}/images/shuffle-additional-settings.svg`,
  redemptionAdditionalSettings: `${BASE_URL}/images/redemption-additional-settings.svg`,
  skipAdditionalSettings: `${BASE_URL}/images/skip-additional-settings.svg`,
  adaptiveAdditionalSettings: `${BASE_URL}/images/adaptive-additional-settings.svg`,
  answerWeightageAdditionalSettings: `${BASE_URL}/images/answer-weightage-additional-settings.svg`,
  passMarksAdditionalSettings: `${BASE_URL}/images/pass-marks-additional-settings.svg`,
  playQuizIcon: `${BASE_URL}/images/play-quiz-icon.svg`,
  pauseQuizIcon: `${BASE_URL}/images/pause-quiz-icon.svg`,
  restartQuizIcon: `${BASE_URL}/images/restart-quiz-icon.svg`,
  cancelQuizIcon: `${BASE_URL}/images/cancel-quiz-icon.svg`,

  //Footer
  footerLinkArrow: `${BASE_URL}/images/footer-link-arrow.svg`,
  privacyPolicy: `${BASE_URL}/files/privacy-policy.html`,

  // Error Screens
  fiveHundredImage: `${BASE_URL}/images/500-image.svg`,
  fourOFourImage: `${BASE_URL}/images/404-image.svg`,
  errorScreenArrow: `${BASE_URL}/images/error-screen-arrow.svg`,

  // Settings
  bookIconSettings: `${BASE_URL}/images/onboarding-book-icon.svg`,
  trashIconSettings: `${BASE_URL}/images/trash-icon-settings.svg`,
};

export default ASSETS;
