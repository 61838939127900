const theme = {
  token: {
    colorPrimary: "#FF6C42",
    colorAccent: "#FFF5F2",
    colorBgLayout: "#F5F7FA",
    border: "1px solid #F0F2F2",
    borderColor: "#F0F2F2",

    colorBlack: "#110D1C",
    colorWhite: "#FFFFFF",
    colorGrey: "#566268",
    colorDarkGrey: "1E1E1E",
    colorLightGreyBackground: "#eeeff2",
    colorLightGrey: "#899195",
    colorGreen: "#8BC34A",
    colorDarkGreen: "#53752C",
    colorLightGreen: "#F3F9ED",
    colorBlue: "#4C769C",
    colorLightBlue: "#F0F8FF",
    colorLightGrey2: "#CECECE",
    colorChatSystemMessage: "#D9E2EC",
    lightOrange: '#FF8968',
    darkerOrange: '#D15835',
    lightGreen: '#B9DB92',

    colorFooterCard: "#F7F7F8",
    colorFooterBorder: "#A6B0B4",

    borderColorPurple: "#9F7AAC",
    borderColorOrange: "#D15835",
    borderColorGreen: "#8BC34A",
    borderColorLime: "#5D5E37",

    textColorGreen: "#6F9C3B",
    colorDarkPurple: "#4D2959",
    colorForestGreen: "#3D6A42",
    textColorGrey: "#6B7B82",
    textColorLightGrey: "#A4A4A4",
    logoBlue: "#145365",
    borderColorGrey: "#E5E5E5",
    loginButtonColor: "#ACE094",

    pricingTextColorGrey: "#505050",
    homeButtonBorderColor: "#B3BAC6",
    dashboardGrey: "#404A4E",
    clockTrailColor: "#D3D7D9",
    colorIncrease: "#384E1E",
    colorIncreaseText: "#8BC34A",

    badgeBgColor: "#E8F3DB",
    onboardingTextColor: "#576181"
  },
};

export default theme;
