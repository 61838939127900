import React, { useState, useEffect } from "react";
import { AutoComplete, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import styled from "styled-components";

const StyledAutoComplete = styled(AutoComplete)`
  width: 100%;
  height: 45px;

  .ant-select-selector {
    border-radius: 10px;
    border: none !important;
    background-color: ${(props) => props.theme.token.colorBgLayout} !important;
  }

  .ant-select-selection-search-input {
    font-size: 14px;
    font-weight: 400;
    font-family: Gilroy-Regular !important;
    color: ${(props) => props.theme.token.onboardingTextColor} !important;
    border: none !important;
  }
`;

const StyledInput = styled(Input)`
  width: 100%;
  background-color: ${(props) => props.theme.token.colorBgLayout} !important;
  height: 45px !important;
  border-radius: 10px !important;
  font-family: Gilroy-Regular !important;

  .ant-input {
    font-family: Gilroy-Regular !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    color: ${(props) => props.theme.token.onboardingTextColor} !important;
    border: none !important;
  }
`;

const AppAutoComplete = ({
  options,
  value,
  onChange,
  placeholder = "Search or Select",
  prefix = <SearchOutlined style={{ paddingRight: "6px" }} />,
}) => {
  const [inputValue, setInputValue] = useState("");

  // Sync inputValue with the value prop
  useEffect(() => {
    if (value) {
      const selectedOption = options.find((opt) => opt.value === value);
      setInputValue(selectedOption ? selectedOption.label : value);
    } else {
      setInputValue("");
    }
  }, [value, options]);

  // Handle search input changes
  const handleSearch = (text) => {
    setInputValue(text);
  };

  // Handle selection
  const handleSelect = (selectedValue, option) => {
    setInputValue(option.label); // Display label instead of ID
    onChange(selectedValue, option);
  };

  return (
    <StyledAutoComplete
      options={options}
      value={inputValue}
      onSearch={handleSearch} // Update input as user types
      onSelect={handleSelect} // Ensure label is displayed when selecting an option
      onChange={(text) => setInputValue(text)} // Allow free text input
      filterOption={(inputValue, option) =>
        option.label.toLowerCase().includes(inputValue.toLowerCase())
      }
      dropdownStyle={{ backgroundColor: "#fff", borderRadius: "10px" }}
    >
      <StyledInput
        placeholder={placeholder}
        value={inputValue}
        onChange={(e) => handleSearch(e.target.value)}
      />
    </StyledAutoComplete>
  );
};

export default AppAutoComplete;
