import React, { useState, useEffect } from "react";
import {
  Modal,
  Typography,
  Space,
  Checkbox,
  AutoComplete,
  Input,
  Button,
  Card,
  Form,
  Row,
  Col,
  Flex,
  message,
} from "antd";
import {
  CloseOutlined,
  SearchOutlined,
  BookOutlined,
  MinusOutlined,
  PlusOutlined,
  DownOutlined,
} from "@ant-design/icons";
import styled from "styled-components";
import { ASSETS } from "../../constants";

const { Text } = Typography;

const StyledCheckbox = styled(Checkbox)`
    font-size: 14px;
  font-weight: 400;
  font-family: "Gilroy-Medium";

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #F7F7F8 !important;
    border-color: #F7F7F8 !important;
  }

  .ant-checkbox-checked:hover .ant-checkbox-inner {
    background-color: #F7F7F8 !important;
    border-color: #F7F7F8 !important;
  }

  .ant-checkbox-inner {
    background-color: #F7F7F8 !important; /* Set background color */
    border-color: #F7F7F8 !important; /* Keep the default border color when unchecked */
  }

  .ant-checkbox:hover .ant-checkbox-inner {
    background-color: #F7F7F8 !important; /* Keep the default background color on hover when unchecked */
    border-color: #F7F7F8 !important; /* Keep the default border color on hover when unchecked */
  }

  /* Change tick color */
  .ant-checkbox-checked .ant-checkbox-inner::after {
    border-color: #757f8e !important; /* Set tick color */
  }
`;

const StyledFormItem = styled(Form.Item)`
  .ant-form-item-label {
    font-size: 14px;
    font-weight: 400;
    font-family: "Gilroy-Regular";
    margin-bottom: 0;

    ::after {
      display: none;
    }
  }
`;

const StyledInput = styled(Input)`
  height: 48px;
    font-size: 14px;
    font-weight: 400;
    font-family: "Gilroy-Regular" !important;
    color: #6B7B82 !important;
    border-radius: 8px !important;
    padding: 12px 16px !important;
    background-color: #F5F7FA !important;
    border: none !important;

    .ant-input-prefix {
      color: #000000 !important;
    }

    .ant-input-suffix {
      color: #000000 !important;
      font-size: 14px !important;
    }
`;

const StyledButton = styled(Button)`
  background: transparent !important;
`;

const AddBookModal = ({
  visible,
  onCancel,
  onSave,
  books,
  publisherOptions,
  ebookOptions,
}) => {
  const [samePublisher, setSamePublisher] = useState(false);
  const [commonPublisher, setCommonPublisher] = useState({
    id: "",
    display: "",
  });
  const [currentBooks, setCurrentBooks] = useState([
    { id: "", name: "", publisher: { id: "", display: "" } },
  ]);
  const [addedBooks, setAddedBooks] = useState(books || []);

  // Update addedBooks when books prop changes
  useEffect(() => {
    setAddedBooks(books || []);
  }, [books]);

  const handleCommonPublisher = (value, option) => {
    setCommonPublisher({ id: option.value, display: option.label });
    const newBooks = currentBooks.map((book) => ({
      ...book,
      publisher: { id: option.value, display: option.label },
    }));
    setCurrentBooks(newBooks);
  };

  const handleBookChange = (index, field, value, option) => {
    const newBooks = [...currentBooks];
    if (field === "name") {
      newBooks[index] = {
        ...newBooks[index],
        id: option.value,
        name: option.label,
      };
    } else if (field === "publisher") {
      newBooks[index] = {
        ...newBooks[index],
        publisher: { id: option.value, display: option.label },
      };
    }
    setCurrentBooks(newBooks);
  };

  const handleAddBookToList = (index) => {
    const bookToAdd = currentBooks[index];
    if (
      bookToAdd.id &&
      (samePublisher ? commonPublisher.id : bookToAdd.publisher.id)
    ) {
      // Check if the book is already in the addedBooks list
      const isBookAlreadyAdded = addedBooks.some((book) => book.id === bookToAdd.id);
  
      if (isBookAlreadyAdded) {
        message.error("This book has already been added.");
      } else {
        setAddedBooks([
          ...addedBooks,
          {
            ...bookToAdd,
            publisher: samePublisher ? commonPublisher : bookToAdd.publisher,
          },
        ]);
        // Clear the current book input
        const newBooks = [...currentBooks];
        newBooks[index] = {
          id: "",
          name: "",
          publisher: { id: "", display: "" },
        };
        setCurrentBooks(newBooks);
      }
    } else {
      message.error("Please select both book and publisher");
    }
  };

  const handleRemoveFromList = (index) => {
    const newAddedBooks = addedBooks.filter((_, i) => i !== index);
    setAddedBooks(newAddedBooks);
  };

  const handleClearInput = (index) => {
    if (currentBooks.length === 1) {
      // If only one book input, just clear the fields
      const newBooks = [...currentBooks];
      newBooks[index] = {
        id: "",
        name: "",
        publisher: { id: "", display: "" },
      };
      setCurrentBooks(newBooks);
    } else {
      // If multiple book inputs, remove the card
      const newBooks = currentBooks.filter((_, i) => i !== index);
      setCurrentBooks(newBooks);
    }
  };

  const handleSave = () => {
    onSave(addedBooks);
  };

  const handleCancel = () => {
    setCurrentBooks([{ id: "", name: "", publisher: { id: "", display: "" } }]);
    setSamePublisher(false);
    setCommonPublisher({ id: "", display: "" });
    onCancel();
  };

  return (
    <Modal
      visible={visible}
      onCancel={handleCancel}
      footer={null}
      width={567}
      closable={false}
      style={{ padding: "30px", borderRadius: "16px", gap: "28px" }}
    >
      <div style={{ gap: "28px", display: "flex", flexDirection: "column" }}>
      {/* Row 1 - Header */}
      <Flex justify="center" align="center" style={{ flexDirection: "column", width: "100%", gap: "10px" }}>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
          <Text strong style={{ fontSize: "16px", fontFamily: "Gilroy-SemiBold", fontWeight: 400 }}>
            Select books and their publisher
          </Text>
          <CloseOutlined onClick={onCancel} style={{ cursor: "pointer" }} />
        </div>
        <Text type="secondary" style={{ fontSize: "14px", fontFamily: "Gilroy-Regular", fontWeight: 400 }}>
          Choose your school books and publishers to help us match your resources!
        </Text>
      </Flex>

      {/* Row 2 - Common Publisher Checkbox */}
      <Space direction="vertical" style={{ width: "100%" }}>
        <StyledCheckbox
          checked={samePublisher}
          onChange={(e) => setSamePublisher(e.target.checked)}
        >
          All the books have same publisher
        </StyledCheckbox>
        </Space>
        <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
        {samePublisher && (
          <StyledFormItem label="Publisher" wrapperCol={{ span: 24 }}>
            <AutoComplete
              options={publisherOptions}
              value={commonPublisher.display}
              onChange={handleCommonPublisher}
              style={{ width: "100%" }}
              dropdownStyle={{ backgroundColor: "#fff" }}
            >
              <StyledInput
                prefix={<SearchOutlined style={{ paddingRight: "12px" }} />}
                placeholder="Search or Select"
                style={{ width: "100%", backgroundColor: "#F5F7FA" }}
                dropdownStyle={{ backgroundColor: "#fff" }}
                suffix={<DownOutlined />}
              />
            </AutoComplete>
          </StyledFormItem>
        )}

      {/* Row 3 - Book Input Section */}
      <Space direction="vertical" style={{ width: "100%" }}>
        {currentBooks.map((book, index) => (
          <Card
            key={index}
            style={{ borderRadius: "16px", gap: "24px", border: "2px dashed #E1E4E6" }}
            bodyStyle={{ padding: 20 }}
          >
            <Space direction="vertical" style={{ width: "100%" }}>
              <StyledFormItem label="Book" wrapperCol={{ span: 24 }}>
                <AutoComplete
                  style={{ flex: 1 }}
                  options={ebookOptions}
                  value={book.name}
                  onChange={(value, option) =>
                    handleBookChange(index, "name", value, option)
                  }
                  dropdownStyle={{ backgroundColor: "#fff" }}
                >
                  <StyledInput
                    prefix={<SearchOutlined style={{ paddingRight: "12px" }} />}
                    placeholder="Search or Select"
                    style={{ width: "100%" }}
                    suffix={<DownOutlined />}
                  />
                </AutoComplete>
              </StyledFormItem>
              {!samePublisher && (
                <StyledFormItem label="Publisher" wrapperCol={{ span: 24 }}>
                  <AutoComplete
                    style={{ flex: 1 }}
                    options={publisherOptions}
                    value={book.publisher.display}
                    onChange={(value, option) =>
                      handleBookChange(index, "publisher", value, option)
                    }
                    dropdownStyle={{ backgroundColor: "#fff" }}
                  >
                    <StyledInput
                      prefix={
                        <SearchOutlined style={{ paddingRight: "12px" }} />
                      }
                      placeholder="Search or Select"
                      style={{ width: "100%" }}
                      suffix={<DownOutlined />}
                    />
                  </AutoComplete>
                </StyledFormItem>
              )}
              <Flex
              style={{ gap: "23px"}}>
                <StyledButton
                  type="text"
                  onClick={() => handleAddBookToList(index)}
                  style={{ padding: "0px" }}
                  bodyStyle={{ padding: 0 }}
                >
                  <Space align="center">
                    <div
                      style={{
                        width: "14px",
                        height: "14px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "50%",
                        backgroundColor: "#8BC34A",
                        color: "#ffffff",
                        fontSize: "7px",
                      }}
                    >
                      <PlusOutlined />
                    </div>
                    <span style={{ fontSize: "14px", fontFamily: "Gilroy-Regular", fontWeight: 400, color: "#000000" }}>
                      Add Book
                    </span>
                  </Space>
                </StyledButton>
                <StyledButton
                  type="text"
                  onClick={() => handleClearInput(index)}
                  style={{ padding: "0px" }}
                  bodyStyle={{ padding: 0 }}
                >
                  <Space align="center">
                    <div
                      style={{
                        width: "14px",
                        height: "14px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "50%",
                        backgroundColor: "#C34244",
                        color: "#ffffff",
                        fontSize: "7px",
                      }}
                    >
                      <MinusOutlined />
                    </div>
                    <span style={{ fontSize: "14px", fontFamily: "Gilroy-Regular", fontWeight: 400, color: "#000000" }}>
                      Remove
                    </span>
                  </Space>
                </StyledButton>
              </Flex>
            </Space>
          </Card>
        ))}
      </Space>
      

      {/* Added Books Section */}
      {addedBooks.length > 0 && (
        <Space
          direction="vertical"
          style={{ width: "100%", marginTop: 0 }}
        >
          <Text style={{fontFamily: "Gilroy-Regular", fontSize: "14px", fontWeight: 400}}>Books Added</Text>
          <Row gutter={[16, 16]}>
            {addedBooks.map((book, index) => (
              <Col xs={24} sm={12} key={index}>
                <Card
                  style={{ backgroundColor: "#F5F7FA", padding: "8px", borderRadius: "8px", border: "none" }}
                  bodyStyle={{ padding: 0 }}
                >
                  <Flex
                    align="center"
                    justify="space-between"
                    style={{ width: "100%" }}
                  >
                    <div
                      style={{
                        width: "44px",
                        height: "44px",
                        backgroundColor: "#FF8968",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "8px",
                        flexShrink: 0,
                      }}
                    >
                      <img src={ASSETS.bookIconSettings} alt="book" />
                    </div>

                    <div style={{ flex: 1, marginLeft: "12px" }}>
                      <Typography.Text
                        style={{ display: "block", fontSize: "14px", fontWeight: 400, fontFamily: "Gilroy-Regular" }}
                      >
                        {book.name}
                      </Typography.Text>
                      <Typography.Text
                        type="secondary"
                        style={{ fontSize: "12px", fontWeight: 400, fontFamily: "Gilroy-Regular" }}
                      >
                        {book.publisher.display}
                      </Typography.Text>
                    </div>

                    <div
                      style={{
                        width: "16px",
                        height: "16px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        margin: "8px",
                        borderRadius: "50%",
                        backgroundColor: "#C34244",
                        color: "#ffffff",
                        cursor: "pointer",
                        fontSize: "8px",
                      }}
                      onClick={() => handleRemoveFromList(index)}
                    >
                      <MinusOutlined />
                    </div>
                  </Flex>
                </Card>
              </Col>
            ))}
          </Row>
        </Space>
      )}
      </div>

      {/* Save Button */}
      <Button
        type="primary"
        size="large"
        onClick={handleSave}
        style={{ width: "100%", fontFamily: "Gilroy-Medium", fontSize: "16px", fontWeight: 400, borderRadius: "10px", height: "51px" }}
      >
        Save
      </Button>
      </div>
    </Modal>
  );
};

export default AddBookModal;
