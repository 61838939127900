import React from "react";
import { Typography, Row, Col, Card, Divider, theme } from "antd";
import styled from "styled-components";

const { Text } = Typography;
const { useToken } = theme;

// Styled Components
const StyledCard = styled(Card)`
  border-radius: 8px;
  background-color: ${(props) => props.theme.token.colorLightGreyBackground};
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 14px 16px;
`;

const Label = styled(Text)`
  font-size: 14px;
  font-weight: 400;
  font-family: Gilroy-Medium;
  display: block;
`;

const Subtitle = styled(Text)`
  font-size: 14px;
  font-weight: 400;
  font-family: Gilroy-Regular;
  color: ${(props) => props.theme.token.colorGrey};
  display: block;
`;

const StyledLink = styled(Text)`
  font-size: 14px;
  font-weight: 400;
  font-family: Gilroy-Medium;
  text-decoration: underline;
  color: ${(props) => props.theme.token.colorPrimary};
  cursor: pointer;
`;

const ValueText = styled(Text)`
  font-size: 24px;
  font-weight: 400;
  font-family: Gilroy-Regular;
  color: ${(props) => props.theme.token.colorGrey};
  display: block;
`;

const PaymentOptions = () => {
  const { token } = useToken();
  return (
    <div id="payment-options">
      <Text
        style={{
          fontSize: "16px",
          fontWeight: "400",
          fontFamily: "Gilroy-SemiBold",
          marginBottom: "16px",
          display: "block",
        }}
      >
        Payment Options
      </Text>

      <Row gutter={[16, 16]}>
        <Col xs={24} md={8}>
          <StyledCard bordered={false} bodyStyle={{ padding: 0 }}>
            <Label>Professional plan monthly renewal</Label>
            <Subtitle>Dec 22, 2024</Subtitle>
            <StyledLink>Upgrade plan to Organization</StyledLink>
          </StyledCard>
        </Col>
        <Col xs={24} md={8}>
          <StyledCard bordered={false} bodyStyle={{ padding: 0 }}>
            <Label>Next invoice issue date</Label>
            <ValueText>Dec 22, 2024</ValueText>
          </StyledCard>
        </Col>
        <Col xs={24} md={8}>
          <StyledCard bordered={false} bodyStyle={{ padding: 0 }}>
            <Label>Invoice total</Label>
            <ValueText>$15</ValueText>
            <StyledLink>Invoices</StyledLink>
          </StyledCard>
        </Col>
      </Row>
      <Divider
        style={{
          borderColor: `${token.colorPrimary}66`,
          marginTop: "36px",
          marginBottom: "24px",
        }}
      />
    </div>
  );
};

export default PaymentOptions;
