import React, { useState } from "react";
import { Typography, Space, Divider, Select, theme } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { AppDropdown } from "../../../components";
import styled from "styled-components";
const { Text } = Typography;
const { useToken } = theme;

const StyledSelect = styled(Select)`
  width: 100%;
  height: 48px;

  .ant-select-selector {
    border-radius: 10px;
    padding: 14px 16px;
    background-color: ${(props) => props.theme.token.colorBgLayout} !important;
    border: none !important;
    font-family: Gilroy-Regular !important;
    font-size: 14px !important;
    font-weight: 300 !important;
    color: ${(props) => props.theme.token.onboardingTextColor} !important;
  }
`;

const PreferLanguage = () => {
  const { token } = useToken();
  const languageOptions = [
    { label: "English", value: "English" },
    { label: "Hindi", value: "Hindi" },
    { label: "Marathi", value: "Marathi" },
  ];
  const [selectedLanguage, setSelectedLanguage] = useState(
    languageOptions[0].value
  );

  const handleLanguageChange = (value) => {
    setSelectedLanguage(value);
  };

  return (
    <div id="prefer-language">
      <Text
        style={{
          fontSize: "16px",
          fontWeight: "400",
          fontFamily: "Gilroy-SemiBold",
          marginBottom: "14px",
          display: "block",
        }}
      >
        Prefer Language
      </Text>
      <Space direction="vertical" style={{ width: "50%" }}>
        <Text style={{fontSize: "14px", fontFamily: "Gilroy-Medium", fontWeight: 400}}>Select Language</Text>
        {/* <AppDropdown
          style={{ width: "100%", height: "48px", backgroundColor: token.colorBgLayout }}
          backgroundColor={token.colorBgLayout}
          options={languageOptions}
          value={selectedLanguage}
          onChange={handleLanguageChange}
          placeholder="Select Language"
          prefix={null}
        /> */}
        <StyledSelect
          value={selectedLanguage}
          onChange={handleLanguageChange}
          options={languageOptions}
          dropdownStyle={{
            borderRadius: "8px",
            backgroundColor: token.colorWhite,
          }}
          size="medium"
          suffixIcon={<DownOutlined style={{ color: token.colorTextSecondary, width: '16px', height: '16px' }} />}
        />
      </Space>
      <Divider
        style={{
          borderColor: `${token.colorPrimary}66`,
          marginTop: "36px",
          marginBottom: "24px",
        }}
      />
    </div>
  );
};

export default PreferLanguage;
