import React from "react";
import { ASSETS } from "./constants";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, errorCode: null };
  }

  static getDerivedStateFromError(error) {
    const errorCode = error?.status || 500;
    return { hasError: true, errorCode };
  }

  componentDidCatch(error, errorInfo) {
    console.error("Error caught in Error Boundary:", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div
          style={{
            height: "100vh", // Full viewport height
            width: "100vw", // Full viewport width
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            overflow: "hidden", // Prevent scrolling
            padding: "16px",
            boxSizing: "border-box",
          }}
        >
          <div
            style={{
              backgroundColor: "#FF6C421A",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              borderRadius: "24px",
              width: "100%",
              height: "100%",
              gap: "58px",
            }}
          >
            {this.state.errorCode === 500 ? (
              <div style={{ textAlign: "center", display: "flex", flexDirection: "column", gap: "8px" }}>
                <span style={{ fontFamily: "Gilroy-Regular", fontSize: "64px", fontWeight: 400 }}>500</span>
                <span style={{ fontFamily: "Gilroy-Regular", fontSize: "24px", fontWeight: 400 }}>
                  Internal Server Error
                </span>
              </div>
            ) : (
              <span
                style={{
                  fontFamily: "Gilroy-Regular",
                  fontSize: "64px",
                  fontWeight: 400,
                  lineHeight: "76.8px",
                  textAlign: "center",
                }}
              >
                Oops!
              </span>
            )}

            <img
              src={this.state.errorCode === 500 ? ASSETS.fiveHundredImage : ASSETS.fourOFourImage}
              alt="error"
              style={{ maxWidth: "100%", maxHeight: "40vh" }} // Ensures image scales properly
            />

            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                cursor: "pointer",
                borderBottom: "1px solid #000000",
                padding: "8px",
                justifyContent: "center",
              }}
              onClick={() => (window.location.href = "/")}
            >
              <img src= {ASSETS.errorScreenArrow} alt="arrow" style={{ width: "35px", height: "auto" }} />
              <span
                style={{
                  fontFamily: "Gilroy-Regular",
                  fontSize: "24px",
                  fontWeight: 400,
                  lineHeight: "28.8px",
                }}
              >
                Go Home
              </span>
            </div>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;