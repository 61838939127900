import React from 'react'
import { renderMarkdownWithLatex } from '../../utils/helpers/latex-parser-helper'
import { Typography } from 'antd'

const { Text } = Typography

const TestLatex = () => {
    const contents = [
        // Basic expressions
        {content: 'Given a **formula** below \n$$\ns = ut + \\frac{1}{2}at^{2}\n$$\n Calculate the value of $s$ when $u = 10\\frac{m}{s}$ and $a = 2\\frac{m}{s^{2}}$ at $t = 1s$'},
        { content: 'Inline equation with $x^2 + y^2 = z^2$' },
        { content: 'Block equation: $$\\int_a^b f(x)dx$$' },
        { content: 'Brackets: \\(\\frac{1}{2}\\) and \\[\\sum_{i=1}^n i\\]' },
        
        // Complex equations
        { content: 'Matrix: $\\begin{pmatrix} 1 & 2 \\\\ 3 & 4 \\end{pmatrix}$' },
        { content: 'Align environment: \\begin{align} x &= y \\\\ y &= z \\end{align}' },
        { content: 'Cases: $f(x) = \\begin{cases} 1 & x \\geq 0 \\\\ 0 & \\text{otherwise} \\end{cases}$' },
        
        // Special symbols
        { content: 'Greek letters: $\\alpha, \\beta, \\Gamma, \\Omega$' },
        { content: 'Operators: $\\pm, \\mp, \\times, \\div, \\cdot$' },
        { content: 'Arrows: $\\rightarrow, \\leftarrow, \\Rightarrow, \\Leftrightarrow$' },
        
        // Chemical equations
        { content: 'Chemical formula: $\\ce{H2O}$' },
        { content: 'Reaction: $\\ce{2H2 + O2 -> 2H2O}$' },
        
        // Multiple equations
        { content: 'Mixed inline $a^2 + b^2$ and block $$c = \\sqrt{a^2 + b^2}$$' },
        { content: 'Consecutive equations: $x + y$ and $\\frac{1}{2}$' },
        
        // Escaped characters
        { content: 'Escaped dollar: \\$100 in equation $x = \\$10$' },
        { content: 'Backslash in text: \\ and in math: $\\backslash$' },
        
        // Code blocks
        { content: '```\n$not_math = true\n``` with real math $x=5$' },
        
        // Complex formatting
        { content: '**Bold math**: $\\mathbf{x} = \\langle x_1, x_2 \\rangle$' },
        { content: 'Mixed markup: _italic_ with $\\hat{x}$ and `code`' },
        
        // Long equations
        { content: 'Long inline: $\\prod_{k=1}^n (1 + x_k) = \\exp\\left(\\sum_{k=1}^n \\ln(1 + x_k)\\right)$' },
        { content: 'Multiline block: $$\\oint_C \\mathbf{E} \\cdot d\\mathbf{l} = -\\frac{d}{dt} \\iint_S \\mathbf{B} \\cdot d\\mathbf{S}$$' },
        
        // Special environments
        { content: 'Equation array: \\begin{eqnarray} x &=& y + z \\\\ a &=& b + c \\end{eqnarray}' },
        { content: 'Gathered equations: \\begin{gathered} x = 1 \\\\ y = 2 \\end{gathered}' },
        
        // Accents and decorations
        { content: 'Accents: $\\hat{a}, \\acute{b}, \\bar{c}, \\dot{d}, \\vec{e}$' },
        { content: 'Over/under: $\\overbrace{a + b}^\\text{sum}$, $\\underbrace{c \\times d}_\\text{prod}$' },
        
        // Probability and statistics
        { content: 'Probability: $P(A|B) = \\frac{P(B|A)P(A)}{P(B)}$' },
        { content: 'Expectation: $\\mathbb{E}[X] = \\int_{-\\infty}^\\infty x f(x)dx$' },
        
        // Quantum mechanics
        { content: 'Bra-ket: $\\langle \\psi | \\phi \\rangle$' },
        { content: 'Commutator: $[\\hat{x}, \\hat{p}] = i\\hbar$' },
        
        // Tensors
        { content: 'Tensor: $T^{\\mu\\nu}_{\\alpha\\beta}$' },
        
        // Units
        { content: 'Units: $5\\,\\mathrm{m/s^2}$' },

        // Normal text
        { content: 'Normal text with no math' },
        { content: 'Normal text with $inline math$' },
        { content: 'Normal text with $$block math$$' },
        { content: 'Normal text with \\(inline math\\)' },
        { content: 'Normal text with \\[block math\\]' },
        { content: 'Normal text with escaped \\$100 and \\backslash' },
        { content: 'Normal text with `code`' },
        { content: 'Normal text with _italic_' },
        { content: 'Normal text with **bold**' },
        { content: 'Normal text with [link](https://example.com)' },
        { content: '![image](https://media.istockphoto.com/id/1316134499/photo/a-concept-image-of-a-magnifying-glass-on-blue-background-with-a-word-example-zoom-inside-the.jpg?s=612x612&w=0&k=20&c=sZM5HlZvHFYnzjrhaStRpex43URlxg6wwJXff3BE9VA=)' },
        { content: 'Normal text with >quote' },
        { content: 'Normal text with ---hr' },
        { content: 'Normal text with 1. list' },
        { content: 'Normal text with - list' },
        { content: 'Normal text with `code block`' },
        { content: 'Normal text with ```\ncode block\n```' },
        { content: 'Normal text with >!spoiler' },
        { content: 'Normal text with $inline math$ and $$block math$$' },
        { content: 'Normal text with \\(inline math\\) and \\[block math\\)' },
        { content: 'Normal text with $inline math$ and \\(inline math\\)' },
        { content: '$100 is a lot of money' },
        { content: 'The backslash \\ is a special character' },

        // Math equations
        { content: 'Inline math: $x = 5$' },
        { content: 'Block math: $$y = 10$$' },
        { content: 'Inline math: \\(x = 5\\)' },
        { content: 'Block math: \\[y = 10\\]' },
        { content: 'Equations: $x = 5$ and $$y = 10$$' },
        { content: 'Equations: \\(x = 5\\) and \\[y = 10\\]' },
        { content: 'Latex text block: ```\n$x = 5$\n```' },
        { content: 'Fraction: $\\frac{1}{2}$' },
        { content: 'Complex fraction: $\\frac{1 + x}{2}$' },
        { content: 'Percentage: $50\\%$' },
        { content: 'Exponent: $x^2$' },
        { content: 'Subscript: $x_1$' },
        { content: 'Superscript: $x^2$' },
        { content: 'Subscript and superscript: $x_1^2$' },
        { content: 'Root: $\\sqrt{x}$' },
        { content: 'Nth root: $\\sqrt[n]{x}$' },
        { content: 'Summation: $\\sum_{i=1}^n i$' },
        { content: 'Product: $\\prod_{i=1}^n i$' },
        { content: 'Integral: $\\int_a^b f(x)dx$' },
        { content: 'Double integral: $\\iint_S f(x,y)dxdy$' },
        { content: 'Triple integral: $\\iiint_V f(x,y,z)dxdydz$' },
        { content: 'Limit: $\\lim_{x \\to \\infty} f(x)$' },
        { content: 'Derivative: $\\frac{df}{dx}$' },
        { content: 'Partial derivative: $\\frac{\\partial f}{\\partial x}$' },
        { content: 'Vector: $\\mathbf{v}$' },
        { content: 'Matrix: $\\begin{pmatrix} 1 & 2 \\\\ 3 & 4 \\end{pmatrix}$' },
        { content: 'Complex fractions with brackets: $\\left(\\frac{1}{2}\\right)$' },
        { content: 'Equation array: \\begin{eqnarray} x &=& y \\\\ a &=& b \\end{eqnarray}' },

        // Special characters
        { content: 'Special characters: $\\alpha, \\beta, \\gamma, \\delta, \\epsilon, \\zeta, \\eta, \\theta, \\iota, \\kappa, \\lambda, \\mu, \\nu, \\xi, \\pi, \\rho, \\sigma, \\tau, \\upsilon, \\phi, \\chi, \\psi, \\omega$' },
        { content: 'Special characters: $\\Alpha, \\Beta, \\Gamma, \\Delta, \\Epsilon, \\Zeta, \\Eta, \\Theta, \\Iota, \\Kappa, \\Lambda, \\Mu, \\Nu, \\Xi, \\Pi, \\Rho, \\Sigma, \\Tau, \\Upsilon, \\Phi, \\Chi, \\Psi, \\Omega$' },
        { content: 'Special characters: $\\aleph, \\beth, \\gimel, \\daleth$' },
        { content: 'Special characters: $\\infty, \\nabla, \\partial, \\hbar, \\imath, \\jmath, \\ell, \\Re, \\Im, \\wp, \\eth, \\mho, \\Bbbk$' },
        { content: 'Special characters: $\\forall, \\exists, \\nexists, \\neg, \\top, \\bot, \\vdash, \\dashv, \\models, \\perp, \\angle, \\sphericalangle, \\measuredangle, \\triangle, \\nabla, \\partial, \\hbar, \\imath, \\jmath, \\ell, \\Re, \\Im, \\wp, \\eth, \\mho, \\Bbbk$' },
        { content: 'Special characters: $\\pm, \\mp, \\times, \\div, \\cdot, \\ast, \\star, \\circ, \\bullet, \\oplus, \\ominus, \\otimes, \\oslash, \\odot, \\dagger, \\ddagger, \\amalg, \\cap, \\cup, \\uplus, \\sqcap, \\sqcup, \\vee, \\wedge, \\setminus, \\wr, \\diamond, \\bigtriangleup, \\bigtriangledown, \\triangleleft, \\triangleright, \\lhd, \\rhd, \\unlhd, \\unrhd, \\oplus, \\ominus, \\otimes, \\oslash, \\odot, \\bigcirc, \\bigodot, \\bigotimes, \\bigoplus, \\biguplus, \\bigcap, \\bigcup, \\bigsqcup, \\bigvee, \\bigwedge, \\bigtriangleup, \\bigtriangledown, \\triangleleft, \\triangleright, \\lhd, \\rhd, \\unlhd, \\unrhd, \\uplus, \\sqcap, \\sqcup, \\vee, \\wedge, \\setminus, \\wr, \\diamond, \\bigtriangleup, \\bigtriangledown, \\triangleleft, \\triangleright, \\lhd, \\rhd, \\unlhd, \\unrhd$' },
        
        // Special environments
        { content: 'Special environments: \n$$\n\\begin{align} x &= y \\\\ y &= z \\end{align}\n$$\nHello world!' },
        { content: 'Special environments: \n$$\n\\begin{gather} x = y \\\\ y = z \\end{gather}\n$$' },
        { content: 'Special environments: \n$$\n\\begin{gathered} x = y \\\\ y = z \\end{gathered}\n$$' },
        { content: 'Special environments: \n$$\n\\begin{aligned} x &= y \\\\ y &= z \\end{aligned}\n$$' },
        { content: 'Special environments: \n$$\n\\begin{alignedat}{3} x + 2 + z &= y \\\\ y &= z \\end{alignedat}\n$$' },
        { content: 'Special environments: \n$$\n\\begin{matrix} a & b \\\\ c & d \\end{matrix}\n$$' },
        { content: 'Special environments: \n$$\n\\begin{pmatrix} a & b \\\\ c & d \\end{pmatrix}\n$$' },
        { content: 'Special environments: \n$$\n\\begin{bmatrix} a & b \\\\ c & d \\end{bmatrix}\n$$' },
        { content: 'Special environments: \n$$\n\\begin{vmatrix} a & b \\\\ c & d \\end{vmatrix}\n$$' },
        { content: 'Special environments: \n$$\n\\begin{Vmatrix} a & b \\\\ c & d \\end{Vmatrix}\n$$' },
        { content: 'Special environments: \n$$\n\\begin{smallmatrix} a & b \\\\ c & d \\end{smallmatrix}\n$$' },
        { content: 'Special environments: \n$$\n\\begin{cases} a & b \\\\ d \\end{cases}\n$$' },
        { content: 'Special environments: \n$$\n\\begin{dcases} a & b \\\\ c & d \\end{dcases}\n$$' },
        { content: 'Special environments: \n$$\n\\begin{rcases} a & b \\\\ c & d \\end{rcases}⇒…\n$$' },
        
        // Degrees and angles
        { content: 'Degrees: $90^\\circ$' },
        { content: 'Angles: $\\angle ABC$' },
        { content: 'Arcs: $\\widehat{ABC}$' },
        { content: 'Sine: $\\sin(x)$' },
        { content: 'Cosine: $\\cos(x)$' },
        { content: 'Tangent: $\\tan(x)$' },
        { content: 'Cotangent: $\\cot(x)$' },
        { content: 'Secant: $\\sec(x)$' },
        { content: 'Cosecant: $\\csc(x)$' },
        { content: 'Hyperbolic sine: $\\sinh(x)$' },
        { content: 'Hyperbolic cosine: $\\cosh(x)$' },
        { content: 'Hyperbolic tangent: $\\tanh(x)$' },
        { content: 'Hyperbolic cotangent: $\\coth(x)$' },
        { content: 'Hyperbolic secant: $\\sec(x)$' },
        { content: 'Hyperbolic cosecant: $\\cosec(x)$' },

        // Calculus
  { content: 'Derivative: $\\frac{d}{dx}\\left( x^2 \\right) = 2x$' },
  { content: 'Partial derivative: $\\frac{\\partial f}{\\partial x}$' },
  { content: 'Definite integral: $$\\int_{0}^{\\pi} \\sin(x)\\,dx = 2$$' },
  { content: 'Indefinite integral: $$\\int e^x\\,dx = e^x + C$$' },
  
  // Algebra & Equations
  { content: 'Quadratic formula: $$x = \\frac{-b \\pm \\sqrt{b^2 - 4ac}}{2a}$$' },
  { content: 'System of equations: $$\\begin{aligned} 2x + 3y &= 5 \\\\ 4x - y &= 11 \\end{aligned}$$' },
  { content: 'Inequalities: $x \\leq y$ and $x \\geq 0$' },
  
  // Matrices and Determinants
  { content: 'Matrix: $\\begin{pmatrix} 1 & 2 \\\\ 3 & 4 \\end{pmatrix}$' },
  { content: 'Determinant: $$\\det\\begin{pmatrix} a & b \\\\ c & d \\end{pmatrix} = ad - bc$$' },
  
  // Logarithms and Exponents
  { content: 'Exponential: $e^{i\\pi} + 1 = 0$' },
  { content: 'Logarithm: $\\log_b(x)$ and natural log $\\ln(x)$' },
  
  // Complex Numbers
  { content: 'Complex number: $z = a + bi$' },
  { content: 'Absolute value: $|z| = \\sqrt{a^2 + b^2}$' },
  
  // Series and Summations
  { content: 'Summation: $$\\sum_{n=1}^{\\infty} \\frac{1}{n^2} = \\frac{\\pi^2}{6}$$' },
  { content: 'Product notation: $$\\prod_{n=1}^{N} n = N!$$' },
  
  // Limits
  { content: 'Limit: $$\\lim_{x \\to 0} \\frac{\\sin(x)}{x} = 1$$' },
  
  // Vector Calculus
  { content: 'Gradient: $\\nabla f(x,y)$' },
  { content: 'Divergence: $\\nabla \\cdot \\mathbf{F}$' },
  { content: 'Curl: $\\nabla \\times \\mathbf{F}$' },
  
  // Advanced/Other Notations
  { content: 'Binomial coefficient: $\\binom{n}{k} = \\frac{n!}{k!(n-k)!}$' },
  { content: 'Modulus: $a \\bmod b$' },
  { content: 'Angle: $\\angle ABC$' },
  
  // Mixed text and math using bracket delimiters
  { content: 'Using brackets: \\(\\sqrt{a^2+b^2}\\) is the hypotenuse.' },
  { content: 'Display using brackets: \\[\\sum_{i=1}^{n} i = \\frac{n(n+1)}{2}\\]' },
  
  // Combined environments and inline math
  { content: 'Combined inline and block: Here is inline $a+b=c$, and below is a block:\n$$\n\\begin{aligned}\na+b &= c \\\\\nd+e &= f\n\\end{aligned}\n$$' },

  // Mixed inline and display math
  { content: 'Multiple inline equations: The quadratic formula $x=\\frac{-b\\pm\\sqrt{b^2-4ac}}{2a}$ is essential in algebra.' },
  { content: 'Combined inline and block: Here is inline math $a+b=c$, and below is a block equation:\n$$\n\\int_0^1 x^2\\,dx = \\frac{1}{3}\n$$' },
  
  // Text formatting with math
  { content: 'Formatted text with math: **Bold text** and _italic text_ along with math: $\\mathbf{F} = m\\mathbf{a}$.' },
  { content: 'Using the \\text command: $\\text{Area} = \\pi r^2$ calculates the area of a circle.' },
  
  // Fractions, radicals, limits, and summations
  { content: 'Fractions and radicals: $\\frac{\\sqrt{a} + \\sqrt{b}}{\\sqrt{c}}$' },
  { content: 'Limit example: $$\\lim_{x \\to 0} \\frac{\\sin(x)}{x} = 1$$' },
  { content: 'Summation: $$\\sum_{i=1}^{n} i = \\frac{n(n+1)}{2}$$' },
  
  // Integrals and derivatives
  { content: 'Integral example: $$\\int_{0}^{1} x^2 dx = \\frac{1}{3}$$' },
  { content: 'Derivative: $\\frac{d}{dx}(x^2)=2x$' },
  
  // Subscripts, superscripts, and accented characters
  { content: 'Subscripts and superscripts: $a_i^2$ and $b^{\\alpha}$' },
  { content: 'Accented characters: $\\acute{e}, \\grave{a}, \\ddot{u}$' },
  
  // Function names and set notations
  { content: 'Trigonometric functions: $\\sin(x), \\cos(x), \\tan(x)$' },
  { content: 'Set notation: $\\{x \\in \\mathbb{R} : x > 0\\}$' },
  
  // Matrices and arrays
  { content: 'Matrix example: $\\begin{pmatrix} 1 & 2 \\\\ 3 & 4 \\end{pmatrix}$' },
  { content: 'Array inside display math: $$\\begin{array}{cc} a & b \\\\ c & d \\end{array}$$' },
  
  // Logarithms, exponents, and roots
  { content: 'Logarithms: $\\log_{10}(x)$ and $\\ln(x)$' },
  { content: 'Exponents and roots: $x^{n}$ and $\\sqrt[n]{x}$' },
  
  // Blackboard bold, calligraphic, and fraktur fonts
  { content: 'Blackboard Bold: $\\mathbb{R}$, Calligraphic: $\\mathcal{L}$, and Fraktur: $\\mathfrak{g}$' },
  
  // Chemical equations (if using mhchem)
  { content: 'Chemical reaction: $\\ce{2H2 + O2 -> 2H2O}$' },
  
  // Nested formatting inside math mode
  { content: 'Nested formatting: $\\textbf{Bold and \\textit{italic} text}$' },
  
  // Miscellaneous symbols and notations
  { content: 'Angle notation: $\\angle ABC$' },
  { content: 'Binomial coefficient: $\\binom{n}{k} = \\frac{n!}{k!(n-k)!}$' },
  
  // General text with LaTeX commands outside math mode
  { content: 'Escaped characters in text: Use \\$ to display a dollar sign and \\\\ for a backslash.' },
  
  // Mixed content with markdown styling
  { content: 'Markdown and LaTeX together: **Bold** text, _italic_ text, `code`, and math $a^2 + b^2 = c^2$ all in one line.' },
    ]

    return (
        <div style={{ padding: 24 }}>
            {contents.map((content, index) => (
                <div key={index} style={{ margin: '16px 0', padding: 16, border: '1px solid #eee', borderRadius: 4 }}>
                    <Text strong>Test Case {index + 1}:</Text>
                    <div style={{ marginTop: 8 }}>
                        {renderMarkdownWithLatex(content.content)}
                    </div>
                </div>
            ))}
        </div>
    )
}

export default TestLatex