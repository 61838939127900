import { STRENGTH_WEAKNESS_URL } from "./api-urls";

export const getStrengthsWeaknesses = async (token) => {
    const requestOptions = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
        },
    };

    const response = await fetch(STRENGTH_WEAKNESS_URL, requestOptions);

    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data;
}