import React, {useState, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import { Typography, Divider, theme, Modal } from "antd";
import styled from "styled-components";
import { AppLogo } from "../components";
import { ASSETS } from "../constants";

const { Text } = Typography;
const { useToken } = theme;

const PrivacyModal = styled(Modal)`
  .ant-modal-body {
    font-size: 14px;
    font-weight: 400;
    color: ${(props) => props.theme.token.textColorGrey};
    font-family: "Gilroy-Regular";
    overflow-y: auto;
    max-height: 60vh;
  }

  .ant-modal-footer {
  }

  .ant-modal-close-x {
    color: black;
  }

  .ant-modal-title {
    font-size: 24px;
    font-weight: 400;
    color: ${(props) => props.theme.token.colorBlack};
    font-family: "Gilroy-Bold";
  }
`;

const FooterContainer = styled.div`
  padding: 60px 120px 0px;
  background-color: ${(props) => props.theme.token.colorWhite};

  @media (max-width: 768px) {
    padding: 40px 20px 0px 20px;
    text-align: center;
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: ${(props) => props.justify || "space-between"};
  align-items: center;
  flex-wrap: wrap;
  padding: ${(props) => props.padding || "20px 0"};

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
  }
`;

const Line = styled(Divider)`
  margin: 10px 0;
  border-color: ${(props) => props.theme.token.colorBorder};

  @media (max-width: 768px) {
    display: none;
    margin: 0;
}
`;

const SocialIcons = styled.div`
  display: flex;
  gap: 8px;

  @media (max-width: 768px) {
    justify-content: center;
  }
`;

const SocialCard = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${(props) => props.theme.token.colorFooterCard};
  border: 1px solid ${(props) => props.theme.token.colorFooterBorder};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  @media (max-width: 768px) {
    margin: 20px 0;
  }
`;

const LinkGroup = styled.div`
  display: flex;
  gap: 20px;
  justify-content: center;
  padding: ${(props) => props.padding || "0"};

  @media (max-width: 768px) {
    flex-wrap: wrap;
    flex-direction: column;
  }
`;

const FooterLink = styled.a`
  font-size: 18px;
  font-weight: 400;
  font-family: "Gilroy-Regular";
  color: ${(props) => props.theme.token.colorBlack};
  text-decoration: none;
  &:hover {
    color: ${(props) => props.theme.token.colorPrimary};
  }
`;

const BottomBar = styled.div`
  background-color: ${(props) => `${props.theme.token.logoBlue}`};
  color: ${(props) => props.theme.token.colorBlack};
  padding: 10px 0px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
    padding: 16px 0;
  }
`;

const BottomContent = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between !important;
  color: ${(props) => props.theme.token.colorBlack};
  align-items: center;
  padding: 0 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
    padding: 0;
    gap: 16px;
  }
`;

const FirstRow = styled(Row)`
  padding: 0 0 20px;

  @media (max-width: 768px) {
    padding: 0px;
  }
`;

const LandingFooter = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [policy, setPolicy] = useState('');
  const { token } = useToken();
  const navigate = useNavigate();

  useEffect(() => {
      const fetchPrivacyPolicy = async () => {
        try {
          const response = await fetch(ASSETS.privacyPolicy);
          if (response.ok) {
            const html = await response.text();
            setPolicy(html);
          } else {
            console.error('Failed to fetch privacy policy file:', response.statusText);
          }
        } catch (error) {
          console.error('Error fetching privacy policy file:', error);
        }
      };
  
      fetchPrivacyPolicy();
    }, []);

  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <>
    <FooterContainer theme={{ token }}>
      {/* First Row */}
      <FirstRow>
        <AppLogo height="80px"/>
        <SocialIcons>
          <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
            <SocialCard theme={{ token }}>
              <img
                src="images/facebook-icon.svg"
                alt="Facebook"
                style={{ height: "20px", width: "auto" }}
              />
            </SocialCard>
          </a>
          <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer">
            <SocialCard theme={{ token }}>
              <img
                src="images/linkedin-icon.svg"
                alt="LinkedIn"
                style={{ height: "20px", width: "auto" }}
              />
            </SocialCard>
          </a>
          <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
            <SocialCard theme={{ token }}>
              <img
                src="images/instagram-icon.svg"
                alt="Instagram"
                style={{ height: "20px", width: "auto" }}
              />
            </SocialCard>
          </a>
          <a href="https://youtube.com" target="_blank" rel="noopener noreferrer">
            <SocialCard theme={{ token }}>
              <img
                src="images/youtube-icon.svg"
                alt="Youtubes"
                style={{ height: "20px", width: "auto" }}
              />
            </SocialCard>
          </a>
        </SocialIcons>
      </FirstRow>

      <Line theme={{ token }} style={{margin: 0}} />

      {/* Second Row */}
      <Row justify="center" padding="40px 0">
        <LinkGroup>
          <FooterLink href="/subjects" onClick={(e) => e.preventDefault() || handleNavigation("/subjects")}>Subjects</FooterLink>
          <FooterLink href="/maths" onClick={(e) => e.preventDefault() || handleNavigation("/maths")}>Mathematics</FooterLink>
          <FooterLink href="/biology" onClick={(e) => e.preventDefault() || handleNavigation("/biology")}>Biology</FooterLink>
          <FooterLink href="/physics" onClick={(e) => e.preventDefault() || handleNavigation("/physics")}>Physics</FooterLink>
          <FooterLink href="/explore" onClick={(e) => e.preventDefault() || handleNavigation("/explore")}>Explore More</FooterLink>
        </LinkGroup>
      </Row>
      </FooterContainer>

      {/* Third Row */}
      <BottomBar theme={{ token }} style={{borderBottom: `4px solid ${token.colorPrimary}`}}>
        <BottomContent>
          <Text style={{ fontSize: "14px", color: `${token.colorWhite}`, fontFamily: 'Gilroy-Medium', fontWeight: '400px' }}>Grade Makers © All Rights Reserved 2025.</Text>
          <LinkGroup>
            <FooterLink style={{color: `${token.colorWhite}`, fontFamily: 'Gilroy-Medium', fontSize: '14px', cursor: 'pointer'}} onClick={() => setIsModalOpen(true)}>Privacy Policy
            <img src={ ASSETS.footerLinkArrow} alt="Footer Link Arrow" style={{height: '14px', width: 'auto', paddingLeft: '6px'}} />
            </FooterLink>
            <FooterLink style={{color: `${token.colorWhite}`, fontFamily: 'Gilroy-Medium', fontSize: '14px'}} href="/termsandconditions" onClick={(e) => e.preventDefault() || handleNavigation("/termsandconditions")}>Terms and Conditions
            <img src={ ASSETS.footerLinkArrow} alt="Footer Link Arrow" style={{height: '14px', width: 'auto', paddingLeft: '6px'}} />
            </FooterLink>
            <FooterLink style={{color: `${token.colorWhite}`, fontFamily: 'Gilroy-Medium', fontSize: '14px'}} href="/privacycenter" onClick={(e) => e.preventDefault() || handleNavigation("/privacycenter")}>Privacy Center
            <img src={ ASSETS.footerLinkArrow} alt="Footer Link Arrow" style={{height: '14px', width: 'auto', paddingLeft: '6px'}} />
            </FooterLink>
          </LinkGroup>

          <PrivacyModal
            title="Privacy Policy"
            visible={isModalOpen}
            onCancel={() => setIsModalOpen(false)}
            footer={null}
            theme={{ token }}
          >
            <div dangerouslySetInnerHTML={{ __html: policy }} />
          </PrivacyModal>
        </BottomContent>
      </BottomBar>
    </>
  );
};

export default LandingFooter;
