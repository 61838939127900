import React, { useState } from "react";
import { QuizSettingsDisplay, QuizDisplay } from "../../../components";
import { quizData, getAuth } from "../../../utils";
import { quizTypes } from "../../../constants";
import { generateQuiz } from "../../../services";
import { useSelectedChapters } from "../../../contexts";
import { message } from "antd";

function Quiz({ bookData }) {
  // State for quiz settings and view toggle
  const [quizStarted, setQuizStarted] = useState(false);
  const [quizType, setQuizType] = useState([quizTypes[0]]);
  const [difficulty, setDifficulty] = useState("Easy");
  const [numQuestions, setNumQuestions] = useState(5);
  const [allQuestions, setAllQuestions] = useState(true);
  const [containsRedemptionQuestions, setContainsRedemptionQuestions] =
    useState(false);
  const [containsSkippedQuestions, setContainsSkippedQuestions] =
    useState(true);
  const [generatedQuizData, setGeneratedQuizData] = useState(null);

  const { selectedChapters } = useSelectedChapters();

  // Function to handle the start quiz action
  const startQuiz = async () => {
    if (selectedChapters.length === 0) {
      message.error("Please select at least one chapter");
      return;
    }

    try {
      const quizRequestData = {
        ebook_id: bookData?.id || "",
        chapter_ids:
          selectedChapters.length > 0
            ? selectedChapters
            : [bookData.chapters[0].id], // Default to first chapter if none selected
        types: quizType.map((type) => type.backend),
        difficulty: difficulty.toLowerCase(),
        num_questions: numQuestions,
      };

      const { authToken } = getAuth();
      console.log("quizRequestData", quizRequestData);
      const response = await generateQuiz(quizRequestData, authToken);

      setGeneratedQuizData(response);
      setQuizStarted(true);
    } catch (error) {
      message.error("Failed to generate quiz: " + error.message);
    }
  };

  // Function to handle the end quiz action
  const handleQuizEnd = () => {
    setQuizStarted(false);
    setGeneratedQuizData(null);
  };

  return (
    <div style={{ width: "100%", height: "100%" }}>
      {/* Conditionally render either QuizSettingsDisplay or QuizDisplay */}
      {!quizStarted ? (
        <QuizSettingsDisplay
          quizType={quizType}
          onQuizTypeChange={setQuizType}
          difficulty={difficulty}
          onDifficultyChange={setDifficulty}
          numQuestions={numQuestions}
          onNumQuestionsChange={setNumQuestions}
          allQuestions={allQuestions}
          onAllQuestionsToggle={setAllQuestions}
          containsRedemptionQuestions={containsRedemptionQuestions}
          onRedemptionQuestionsToggle={setContainsRedemptionQuestions}
          containsSkippedQuestions={containsSkippedQuestions}
          onSkippedQuestionsToggle={setContainsSkippedQuestions}
          onStartQuiz={startQuiz}
          book={bookData}
        />
      ) : (
        <QuizDisplay
          quizType={quizType}
          difficulty={difficulty}
          numQuestions={numQuestions}
          allQuestions={allQuestions}
          quizData={generatedQuizData || quizData}
          onQuizEnd={handleQuizEnd}
        />
      )}
    </div>
  );
}

export default Quiz;
